const id = 'len';
let defaultProps = {
    max:Infinity,
    min:1
}
const extractProps = function(validator){
    const props = validator.props || {};
    const keys = Object.keys(props);
    for (const key in keys){
        defaultProps[keys[key]] = props[keys[key]];
    }
}
const extractValue = function(field){
    return field.value || '';
}
const validateLength = function (field,validator){
    extractProps(validator)
    const value = extractValue(field).trim();
    let isValid = (value.length <= defaultProps.max) && (value.length >= defaultProps.min);
    return {
        isValid:isValid,
        message: isValid ? '' : `El campo ${field.field.description.toLowerCase()} debe de tener un máximo de ${defaultProps.max} y un mínimo de ${defaultProps.min} caracteres.`
    }
}
module.exports = {
    id:id,
    validate:validateLength
}

