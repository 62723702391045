/* Copyright (C) Startuplab MX. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Juan Hernandez <juan@startuplab.mx> September 2020
*/

import React from "react";
import PropTypes from 'prop-types';
import DLForm from "../DLForm";
import DocuLegalApi from "../../api/DocuLegalApi";
import { Link } from "react-router-dom";
import { isLoginComplete } from "../validators/utils.js";
import Swal from "sweetalert2";

class LoginComponent extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            currentForm: [],
            fields: {},
        }
        this.onLogin = this.onLogin.bind(this);
        this.onLoginSuccess = this.onLoginSuccess.bind(this);
        /* Use ref to access DLForm state*/
        this.DLForm = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        return { currentForm: props.loginForm.forms[0] }
    }

    onLoginSuccess(res) {
        this.props.onLoginSuccess(res);
    }
    onLoginFailed(res) {
        this.props.onLoginFailed(res);
    }

    onChange(change) {
        let key = Object.keys(change)[0];
        let fields = this.state.fields;
        fields[key] = change[key]
        this.setState({
            fields
        });
        this.props.onChange(change);
    }

    onLogin() {
        let fields = this.state.fields;
        if (this.isValidForm()) {
            DocuLegalApi.login(
                fields.email.value,
                fields.password.value
            )
                .then((res) => {
                    if (res.success) {
                        this.onLoginSuccess(res);
                    } else {
                        this.onLoginFailed(res);
                    }
                })
                .catch((err) => { });
        } else {
            Swal.fire({
                icon: "error",
                text: "Revisa tus datos, te recordamos que debes de llenar todos los campos.",
                confirmButtonText:"Volver a intentar"
            });
        }
    }

    /* Validate form with validations in DLForm*/
    isValidForm() {
        let fields = this.DLForm.state;
        if (isLoginComplete(fields.validations)) {
            fields = fields.validations;
            if (fields.email.isValid && fields.password.isValid) {
                return true
            }
        }
        return false
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.onLogin();
        }
    };

    render() {
        return (
            <div className="form-signin mb-5">
                <h2 className="form-signin-heading">{this.props.title}</h2>
                <div className="login-wrap">
                    <div className="col-lg-12">
                        <p className="text-left">¿No tienes cuenta?
                    <Link className="navbar-brand pl-2" to={{ pathname: '/sign-in', params: { doc: document.doc } }}>
                        Registrate
                    </Link>
                        </p>
                    </div>
                    <div onKeyPress={(event) => this.handleKeyPress(event)}>
                        <DLForm 
                            onChange={(change) => this.onChange(change)} 
                            form={this.state.currentForm} 
                            ref={(DLForm) => { this.DLForm = DLForm }}
                        >
                        </DLForm>
                    </div>
                    <div className="col-lg-12 m-bot20">
                        <a href={'/recuperar-contrasena'}>¿Olvidaste tu contraseña? Da click aquí</a>
                    </div>
                    <div className="col-lg-12">
                        <button className="btn btn-lg btn-danger btn-block" onClick={() => this.onLogin()}>Iniciar sesión</button>
                    </div>
                </div>
            </div>
        )
    }
}

LoginComponent.propTypes = {
    /* Login Form */
    loginForm: PropTypes.array,
    /* Title Form */
    title: PropTypes.string,
    /* When the values are changed call this function */
    onChange: PropTypes.func,
    /* If the user login is successful call this function */
    onLoginSuccess: PropTypes.func,
    /* If the user login is not successful call this function */
    onLoginFailed: PropTypes.func
}
LoginComponent.defaultProps = {
    loginForm: [],
    title: '',
    onChange: (change) => { },
    onLoginSuccess: (res) => { },
    onLoginFailed: (res) => { }
};

export default LoginComponent;
