/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by America Mendoza <amendoza@nodeport.co> August 2020
*/
import React from 'react';
import PropTypes from 'prop-types';

class DLErrorDisplay extends React.Component{

    renderContent(errors){
        if(errors.length === 1){
            return( errors[0].message );
        }else{
            return(
                <ul>
                    {errors.map( error => {
                        return (
                            <li>{error.message}</li>
                        )}
                        )
                    }
                </ul>
            )
        }
    }

    render(){
        const errors = this.props.errors;
        if(errors.length > 0){
            return (
                <label className="error">{this.renderContent(errors)}</label>
            )
        }else{
            return null;
        }
    }
}
export default DLErrorDisplay;
DLErrorDisplay.propTypes = {
    errors: PropTypes.array
};
DLErrorDisplay.defaultProps = {
    errors: [],
};

