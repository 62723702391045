/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Darien Miranda <dmiranda@nodeport.co> & Oscar Pena <opena@nodeport.co> August 2020
*/

// Regular imports
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import styling
import '../style/jquery.steps.css'
import '../style/bootstrap-reset.css'
import '../style/style.css'
import '../style/style-responsive.css'

// Import components
import DLDocumentsGrid from '../components/DLDocumentsGrid'
import DLHeader from "../components/DLHeader";


// Import API
const DocuLegalApi = require('../api/DocuLegalApi');

class SignatureView extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            documents:[],
            filtered:[],
            documentsLoadState:'success',
        }
    }
    getDocuments(){
        DocuLegalApi.getTemplates().then((templates) =>{
            this.setState({
                documents: templates.templates,
                filtered: templates.templates,
            })
        })
    }
    componentDidMount() {
        this.getDocuments();
    }

    renderContent() {
        if (this.state.filtered) {
            return (
                <DLDocumentsGrid
                    documents={this.state.filtered}
                    requestStatus={this.state.documentsLoadState} />
            )
        }
    }

    filterByCategory(category){
        let documents = this.state.documents;
        if( category !== 'all'){
            documents = documents.filter(doc => doc.category === category);
        }
        this.setState({filtered: documents});
    }

    render() {
        return (
            <section id="container">
                {/*header start*/}
                <DLHeader />
                {/*header end*/}
                {/* Page wrapper  */}
                <div style={{"paddingTop":"80px"}}>
                    <div className="container">
                        <section className="card">
                            <header className="card-header">
                                Documentos
                                <span className="float-right">
                                   <button type="button"
                                           className="btn btn-sm mr-1"
                                           onClick={() => this.filterByCategory('all')}>TODOS
                                   </button>
                                   <button type="button"
                                           className="btn btn-primary btn-sm mr-1"
                                           onClick={() => this.filterByCategory('pyme')}>
                                        <FontAwesomeIcon
                                            className="mr-auto ml-auto"
                                            color="white"
                                            icon={'building'}/> PYME
                                    </button>
                                    <button type="button"
                                            className="btn btn-success btn-sm mr-1"
                                            onClick={() => this.filterByCategory('personal')}>
                                        <FontAwesomeIcon
                                            className="mr-auto ml-auto"
                                            color="white"
                                            icon={'user-tie'}/> PERSONAL
                                    </button>
                                    <button type="button"
                                            className="btn btn-warning btn-sm"
                                            onClick={() => this.filterByCategory('otro')}>
                                        <FontAwesomeIcon
                                            className="mr-auto ml-auto"
                                            color="white"
                                            icon={'file-contract'}/> OTRO
                                    </button>
                                </span>
                            </header>
                        </section>
                        {/* Start Page Content */}
                        {this.renderContent()}
                        {/* End PAge Content */}
                    </div>
                </div>
                {/* End Page wrapper  */}
            </section>
        )
    }
}
export default SignatureView;
