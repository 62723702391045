const id = 'hourv';
let defaultProps = {
}
const extractProps = function(validator){
    const props = validator.props || {};
    const keys = Object.keys(props);
    for (const key in keys){
        defaultProps[keys[key]] = props[keys[key]];
    }
}
const extractValue = function(field){
    return field.value || '';
}
const validateHourv = function (field,validator){
    extractProps(validator)
    const value = extractValue(field).trim();
    let isValid  = (value.split(':')[0] < 24) && (value.split(':')[0] >= 0)
                  && (value.split(':')[1] < 60) && (value.split(':')[1] >= 0);
    return {
        isValid:isValid,
        message: isValid ? '' :
        `El campo ${field.field.description.toLowerCase()}
        no es un formato de hora valido]`
    }
}
module.exports = {
    id:id,
    validate:validateHourv
}
