/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <america@startuplab.mx>, August 2020
 */
const axios = require('axios');
const Session = require("./Session");
const getAuthHeaders = () =>{
    return {
        headers:{
            'Authorization': `Bearer: ${getAuthToken()}`
        }
    }
}
const getAuthToken = () =>{
    return localStorage.getItem('NPAuthToken');
}
const DocuLegalApi = {
    API_URL: process.env.REACT_APP_API_URL,
    getMe() {
        return axios.get(DocuLegalApi.API_URL + '/users/me',getAuthHeaders()).then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        })
    },
    getTemplates() {
        return axios.get(DocuLegalApi.API_URL + '/templates',getAuthHeaders()).then(res => {
            return res.data;
        })
            .catch(err => {
                return err;
            })
    },
    getTemplateByUrl(url) {
        return axios.get(`${DocuLegalApi.API_URL}/templates/${url}`,getAuthHeaders()).then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        })
    },
    getDocumentById(id) {
        return axios.get(`${DocuLegalApi.API_URL}/documents/${id}`,getAuthHeaders()).then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        })
    },
    getMyDocuments() {
        return axios.get(DocuLegalApi.API_URL + '/documents',getAuthHeaders()).then(res => {

            return res.data;
        })
        .catch(err => {
            return err;
        })
    },
    login(email, password) {
        let params = {
            email: email,
            password: password
        };
        return axios.post(DocuLegalApi.API_URL + '/users/login', params).then(res => {
            Session.saveUser(res.data.result.user);
            return res.data;
        })
        .catch(err => {
            return err;
        })
    },
    addFIELSignatories(documentId, signatories) {
        let params = {
            signatories: signatories
        }
        return axios.post(`${DocuLegalApi.API_URL}/documents/${documentId}/addFIELSignatories`, params,getAuthHeaders());
    },
    resendSignatureRequest(documentId) {
        return axios.post(`${DocuLegalApi.API_URL}/documents/${documentId}/resendSignatureRequest`, {},getAuthHeaders());
    },
    cancelSignatures(documentId) {
        return axios.post(`${DocuLegalApi.API_URL}/documents/${documentId}/cancelSignatures`, {},getAuthHeaders());
    },
    addSignatories(documentId, signatories) {
        let params = {
            signatories: signatories
        }
        return axios.post(`${DocuLegalApi.API_URL}/documents/${documentId}/addSignatories`, params,getAuthHeaders());
    },
    sign(documentId, signatoryToken,signature) {
        let params = {
            signatoryToken: signatoryToken,
            signature:signature
        }
        return axios.post(`${DocuLegalApi.API_URL}/documents/${documentId}/sign`, params,getAuthHeaders());
    },
    createUser(name, last_name, email, password) {
        let params = {
          name: name,
          password:password,
          email:email,
          last_name:last_name
        };
        return axios.post(DocuLegalApi.API_URL + '/users/create', params).then(res => {
          return res.data;
        })
        .catch(err => {
          return err;
        })
    },
    createDocument(templateId,document,signatories) {
        let params = {
            templateId:templateId,
            htmlDocument:document,
            signatories:signatories
        }
        return axios.post(`${DocuLegalApi.API_URL}/documents/create`, params,getAuthHeaders());
    },
    validateCode(code) {
        let params = {
            code: code
        };
        return axios.post(DocuLegalApi.API_URL + '/users/me/validate', params,getAuthHeaders());
    },
    requestValidation(method = 'email', phoneNumber = '') {
        let params = {
            method: method,
            phoneNumber: phoneNumber
        };
        return axios.post(DocuLegalApi.API_URL + '/users/me/request_validation', params, getAuthHeaders());
    },
    getQuota() {
        return axios.get(DocuLegalApi.API_URL + '/users/me/quota',getAuthHeaders()).then(res => {
            return res.data;
        })
            .catch(err => {
                return err;
            })
    },
    getHistory() {
        return axios.get(DocuLegalApi.API_URL + '/users/me/payments/history',getAuthHeaders()).then(res => {
            return res.data;
        })
            .catch(err => {
                return err;
            })
    },
    makePayment(item,token,customerInfo){
        let params = {
            item : item,
            customerInfo : customerInfo,
            token : token
        }
        return axios.post(DocuLegalApi.API_URL + '/store/buy_documents', params, getAuthHeaders());
    },
    getUsers() {
        return axios.get(DocuLegalApi.API_URL + '/admin/users',getAuthHeaders()).then(res => {
            return res.data;
        })
        .catch(err => {
                return err;
        })
    },
    getUser(userID) {
        let params ={
            userID: userID
        }
        return axios.post(DocuLegalApi.API_URL + '/admin/user',params, getAuthHeaders()).then(res => {
            return res.data;
        })
            .catch(err => {
                return err;
            })
    },
    getDocsInfo(userID) {
        let params ={
            userID: userID
        }
        return axios.post(DocuLegalApi.API_URL + '/admin/getDocsInfo',params, getAuthHeaders()).then(res => {
            return res.data;
        })
            .catch(err => {
                return err;
            })
    },
    adminUpdateUser(userID,name,last_name) {
        let params ={
            userID: userID,
            name: name,
            last_name:last_name
        }
        return axios.post(DocuLegalApi.API_URL + '/admin/updateUserName',params, getAuthHeaders()).then(res => {
            return res.data;
        })
            .catch(err => {
                return err;
            })
    },
    getUserQuota(userID) {
        let params = {
            userID:userID,
        }
        return axios.post(DocuLegalApi.API_URL + '/admin/user/quota',params, getAuthHeaders()).then(res => {
            return res.data;
        })
            .catch(err => {
                return err;
            })
    },
    deleteUsers(userID) {
        let params ={
            userID: userID,
        }
        return axios.post(DocuLegalApi.API_URL + '/admin/deleteUsers',params, getAuthHeaders()).then(res => {
            return res.data;
        })
            .catch(err => {
                return err;
            })
    },
    disableUsers(userID) {
        let params ={
            userID: userID,
        }
        return axios.post(DocuLegalApi.API_URL + '/admin/disableUsers',params, getAuthHeaders()).then(res => {
            return res.data;
        })
            .catch(err => {
                return err;
            })
    },
    enableUsers(userID) {
        let params ={
            userID: userID,
        }
        return axios.post(DocuLegalApi.API_URL + '/admin/enableUsers',params, getAuthHeaders()).then(res => {
            return res.data;
        })
            .catch(err => {
                return err;
            })
    },
    updateUser(name,last_name) {
        let params = {
            name: name,
            last_name: last_name
        };
        return axios.post(DocuLegalApi.API_URL + '/users/me/update_profile', params, getAuthHeaders());
    },
    requestPasswordReset(email){
        let params = {
            email: email,
        };
        return axios.post(DocuLegalApi.API_URL + '/users/request_password_reset', params, getAuthHeaders());
    },
    validatePasswordResetCode(code) {
        let params = {
            code: code,
        };
        return axios.post(`${DocuLegalApi.API_URL}/users/validate_password_reset_code`,params,getAuthHeaders()).then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        })
    },
    updatePassword(code,password) {
        let params = {
            code: code,
            password:password
        };
        return axios.post(`${DocuLegalApi.API_URL}/users/update_password`,params,getAuthHeaders()).then(res => {
            return res.data;
        })
            .catch(err => {
                return err;
            })
    },
    createPreview(html) {
        let params = {
            htmlDocument:html
        };
        return axios.post(`${DocuLegalApi.API_URL}/documents/createEditorPreview`,params,getAuthHeaders()).then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        })
    },
    markFIELAsSigned(documentId,widgetId) {
        return axios.post(`${DocuLegalApi.API_URL}/documents/${documentId}/markFIELAsSigned?widget_id=${widgetId}`,{},getAuthHeaders()).then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        })
    },
    downloadMIFIELDoc(documentId) {
        return axios.get(`${DocuLegalApi.API_URL}/documents/${documentId}/downloadMIFIELDoc`,{},getAuthHeaders()).then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        })
    },
    downloadMIFIELXML(documentId) {
        return axios.get(`${DocuLegalApi.API_URL}/documents/${documentId}/downloadMIFIELDoc?extension=xml`,{},getAuthHeaders()).then(res => {
            return res.data;
        })
            .catch(err => {
                return err;
            })
    },
}
module.exports = DocuLegalApi;
