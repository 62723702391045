/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by America Mendoza <amendoza@nodeport.co>
*/
//imports
import React from "react";
import PropTypes from 'prop-types';
import DocuLegalApi from "../api/DocuLegalApi";

//import css
import '../style/bootstrap-reset.css'

//import const
const plans = {
    'plan_5':{
        name:'Paquete 5',
        item: 'small',
        price: '$900.00 MXN'
    },
    'plan_20':{
        name:'Paquete 20',
        item: 'medium',
        price: '$2800.00 MXN'
    },
    'plan_50':{
        name:'Paquete 50',
        item: 'large',
        price: '$4900.00 MXN'
    }
}
class UpgradePlan extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            current_plan:''
        }
        this.getMe                       = this.getMe.bind(this);
        this.upgrade                      = this.upgrade.bind(this);
    }

    componentDidMount(){
        this.getMe();
    }

    getMe(){
        DocuLegalApi.getMe().then((u) =>{
            if(u.success){
                this.setState({
                    authorized:true,
                    verified:u.user.isVerified,
                    current_plan:u.user.current_tier
                });
            }else{
                this.setState({
                    authorized:false,
                    verified:false
                });
            }
        }).catch((err) =>{
            this.setState({
                authorized:false,
                verified:false
            });
        })
    }

    upgrade(plan){
        this.props.history.push({
            pathname:"/pagar-plan",
            state:{
                plan : plans[plan],
                afterSuccessfulPaymentUrl: this.props.afterSuccessfulPaymentUrl
            }
        });
    }
    renderUpgradeButton(plan){
        if( plan === this.state.current_plan){
            return(
                <div>Tu paquete actual</div>
            )
        }else{
            return(
                <button className="btn" onClick={() => this.upgrade(plan)}>Comprar</button>
            )
        }

    }
    render(){
        return (
            <div className="container">
                <div className={"row mt-5"}>
                    <div className={"col-lg-12 my-4"}>
                        <h3 className={'mb-4'}>Elige tu paquete</h3>
                        <p>Compra un paquete y utilízalo para firmar tus documentos.</p>
                    </div>

                    <div className="col-lg-3 col-sm-4 ml-auto mb-2">
                        <div className="pricing-table  most-popular">
                            <div className="pricing-head">
                                <h1> Paquete 5 </h1>
                                <h2><span className="note">$</span>900 <small>mxn</small></h2>
                            </div>
                            <ul className="list-unstyled">
                                <li>5 firmas FIEL</li>
                                <li>Un solo pago</li>
                                <li>Firma con tu FIEL</li>
                            </ul>
                            <div className="price-actions">
                                {this.renderUpgradeButton('plan_5')}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-4 mb-2">
                        <div className="pricing-table most-popular">
                            <div className="pricing-head">
                                <h1> Paquete 20 </h1>
                                <h2><span className="note">$</span>2,800 <small>mxn</small></h2>
                            </div>
                            <ul className="list-unstyled">
                                <li>20 firmas FIEL</li>
                                <li>Un solo pago</li>
                                <li>Firma con tu FIEL</li>
                            </ul>
                            <div className="price-actions">
                                {this.renderUpgradeButton('plan_20')}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-4 mr-auto mb-2">
                        <div className="pricing-table  most-popular">
                            <div className="pricing-head">
                                <h1> Paquete 50 </h1>
                                <h2><span className="note">$</span>4,900 <small>mxn</small></h2>
                            </div>
                            <ul className="list-unstyled">
                                <li>50 firmas FIEL</li>
                                <li>Un solo pago</li>
                                <li>Firma con tu FIEL</li>
                            </ul>
                            <div className="price-actions">
                                {this.renderUpgradeButton('plan_50')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
)
    }
}

UpgradePlan.propTypes = {
    /** Document to be render. */
    upgradeTo: PropTypes.string,
    afterSuccessfulPaymentUrl: PropTypes.string
};

UpgradePlan.defaultProps = {
    upgradeTo: '',
    afterSuccessfulPaymentUrl : null
};

export default UpgradePlan;
