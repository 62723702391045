/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by America Mendoza <amendoza@nodeport.co>
*/
//imports
import React from "react";
import PropTypes from 'prop-types';
import { PDFViewer } from 'react-view-pdf';
import Swal from "sweetalert2";
import DocuLegalApi from "../api/DocuLegalApi";

//import css
import '../style/jquery.steps.css'
import '../style/bootstrap-reset.css'

//import components
import DLHeader from "../components/DLHeader";
import DLFooter from "../components/DLFooter";
import DLModalSendSignatureRequest from '../components/DLModalSendSignatureRequest';
import DLSelectSignatureType from "../components/DLSelectSignatureType";
import DLRequestValidationCode from "../components/DLRequestValidationCode";
import CFRegistration from "../components/CFRegistration";
import CreditsPopup from "../components/store/CreditsPopup";
import DLIf from "../components/render/DLIf";

//import const
const Handlebars = require("handlebars");

class DocumentPreviewView extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            docId: '',
            document: {
                body        : '',
                description : '',
                forms       : [],
                name        : '',
                template    : {
                    name : ''
                }
            },
            compiledDocument:'',
            previewUrl:'',
            showSendSignatureModal:false,
            signature:false,
            authorized:false,
            showSelectSignature:false,
            selectedSignatoryType:null,
            checkPermissions: false
        }
        this.handleModalSendSignature   = this.handleModalSendSignature.bind(this);
        this.handleSelectSignature      = this.handleSelectSignature.bind(this);
        this.onRequestSignatories       = this.onRequestSignatories.bind(this);
        this.signatureDocument          = this.signatureDocument.bind(this);
        this.navigateToDoc              = this.navigateToDoc.bind(this);
        this.onSelectSignature          = this.onSelectSignature.bind(this);
        this.onClickSign                = this.onClickSign.bind(this);
        this.getMe                      = this.getMe.bind(this);
    }

    componentDidMount(){
        const docId = this.props.match.params.id;

        const previewUrl = `${process.env.REACT_APP_API_URL}/documents/${docId}/preview`;
        this.setState({previewUrl:previewUrl});

        DocuLegalApi.getDocumentById(docId).then((res) =>{
            if(res.success){
                this.setState({
                    document:res.document
                })
            }
        });
        //get if the user is authorized or is a verified user
        this.getMe()

        let File = this.props.location;

        if(File.hasOwnProperty('params')){
            const template = Handlebars.compile(File.params.doc.body);
            const compiledDocument = template({});

            this.setState({compiledDocument});
            //get document
            this.setState({document: File.params.doc});
        }
    }

    getMe(){
        DocuLegalApi.getMe().then((u) =>{
            if(u.success){
                this.setState({
                    authorized:true,
                    verified:u.user.isVerified
                });
            }else{
                this.setState({
                    authorized:false,
                    verified:false
                });
            }
        }).catch((err) =>{
            this.setState({
                authorized:false,
                verified:false
            });
        })
    }

    navigateToDoc(){
        const docId = this.props.match.params.id;
        const type = this.props.match.params.type;
        this.props.history.push(`/documents/${type}/${docId}`);
    }

    navigateTo(path){
        const docId = this.props.match.params.id;
        const type = this.props.match.params.type;
        localStorage.setItem('onLoginSuccessUrl',`/documents/${type}/${docId}/preview`)
        this.props.history.push({
            pathname:"/login"
        });
        this.props.history.push(path);
    }

    handleSelectSignature(showSelectSignature){
        this.setState({showSelectSignature});
    }

    onSelectSignature(_type){
        this.setState({selectedSignatoryType:_type}, () =>{
            this.handleSelectSignature(false);
            this.handleModalSendSignature(true);
        })
    }

    onClickSign(){
        //Check if the user is authorized
        if(this.state.authorized && this.state.verified) {
            this.handleSelectSignature(true);
            this.setState({checkPermissions: false});
        }else{
            this.setState({checkPermissions: true});
        }
    }

    signatureDocument(){
        this.setState({signature:true});
    }

    handleModalSendSignature(showSendSignatureModal){
        this.setState({showSendSignatureModal})
    }
    goToMyDocuments(){
        this.props.history.push({
            pathname:"/perfil",
            state:{
                section:'documents'
            }
        });
    }
    onConfirmFIELSignatures(data){
        if(data.signingMethod === 'fiel'){
            DocuLegalApi.addFIELSignatories(data.docId, data.signatories).then((res) => {
                Swal.fire({
                        title:"Solicitudes de firma enviadas",
                        icon:"success",
                        confirmButtonText:"Ir a mi cuenta"
                    }
                ).then((result) => {
                    this.goToMyDocuments();
                })
            }).catch((err) => {
                let errMsg = "No pudimos solicitar las firmas. Intenta más tarde.";
                try {
                    errMsg = err.response.data.error_es;
                }catch (ex){
                }
                if(err.response.data.quota_reached){
                    Swal.fire({
                        title:"Firmas insuficientes",
                        text:errMsg,
                        icon:"error",
                        confirmButtonText: "Comprar firmas",
                        cancelButtonText:"Cancelar",
                        showCancelButton:true,
                        showConfirmButton:true
                    }).then((result) =>{
                        if (result.isConfirmed) {
                            const docId = this.props.match.params.id;
                            const type = this.props.match.params.type;
                            const afterSuccessfulPaymentUrl = `/documents/${type}/${docId}/preview`;
                            this.props.history.push({
                                pathname:"/perfil",
                                state:{
                                    section:'shop',
                                    afterSuccessfulPaymentUrl: afterSuccessfulPaymentUrl
                                }
                            });
                        }
                    });
                }else{
                    Swal.fire({
                        title:"Ocurrió un error",
                        text:errMsg,
                        icon:"error",
                        confirmButtonText: "Aceptar",
                    });
                }

            })
        }else{
            DocuLegalApi.addSignatories(data.docId,data.signatories).then((res) =>{
                if(res.data.success){
                    if(this.state.selectedSignatoryType !== 'autograph'){
                        Swal.fire({
                            title:"Solicitudes de firma enviadas",
                            icon:"success",
                            confirmButtonText:"Ir a mi cuenta"
                        }).then((result) => {
                            this.goToMyDocuments();
                        })
                    }else{
                        let docId = "";
                        if (res.data.document){
                            docId = res.data.document._id;
                        }
                        Swal.fire({
                                title:"Documento generado",
                                text:"El documento ha sido generado exitosamente",
                                icon:"success",
                                confirmButtonText: "Descargar documento",
                                cancelButtonText: "Cerrar",
                            }
                        ).then((result) => {
                            if(result.isConfirmed){
                                this.downloadPDF(docId);
                            }
                        });
                    }
                }
            }).catch((err) =>{
                let errMsg = "No pudimos solicitar las firmas. Intenta más tarde.";
                try {
                    errMsg = err.response.data.error_es;
                }catch (ex){
                }
                Swal.fire({
                    title:"Ocurrió un error",
                    text:errMsg,
                    icon:"error",
                    confirmButtonText: "Aceptar",
                });
            });
        }
    }
    onRequestSignatories(signatories){
        if(this.state.selectedSignatoryType === 'fiel') {
            signatories = signatories.filter((s) => {
                return (!!s.name || !!s.email || !!s.tax_id)
            })
            signatories = signatories.map((s) => {
                const ss = s;
                s.signingMethod = 'fiel';
                return ss;
            })
            const docId = this.props.match.params.id;
            this.fielMethodCreditsPopup.open(
                {
                    signatories:signatories,
                    docId:docId,
                    signingMethod:'fiel'
                }
            );
        }else{
            signatories = signatories.filter((s) =>{
                return (!!s.name || !!s.email )
            }).map((s) => {
                const sm = {...s};
                sm.signingMethod = this.state.selectedSignatoryType
                return sm;
            })
            const docId = this.props.match.params.id

            this.otherMethodcreditsPopup.open(
                {
                    signatories:signatories,
                    docId:docId,
                    signingMethod:this.state.selectedSignatoryType
                }
            );

        }
    }

    downloadPDF(documentId){
        const url = `${process.env.REACT_APP_API_URL}/documents/${documentId}/preview`
        window.open(url,'_blank');
        setTimeout(() =>{
            this.goToMyDocuments();
        },500)
    }

    renderPreview(){
        const shouldShowSignButton = this.state.document.signatories ? this.state.document.signatories.length === 0 ? true : false : false;
        return (
            <section className="wrapper">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body d-flex flex-row align-items-center">
                                <h5 className={'m-0'}>Firma tu {this.state.document.template.name.toLowerCase()}</h5>
                                <div className="ml-auto">
                                    <DLIf if={shouldShowSignButton}>
                                        <button onClick={() => this.onClickSign()}
                                                className="btn btn-success">
                                            Firmar
                                        </button>
                                    </DLIf>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <PDFViewer url={this.state.previewUrl} />
                </div>
            </section>
        )
    }

    renderContent(){
        if(this.state.checkPermissions){
            if(this.state.authorized){
                if(this.state.verified){
                    return this.renderPreview();
                }else{
                    return <DLRequestValidationCode onValidationSuccess={() => {
                        this.handleSelectSignature(true);
                        this.getMe()
                        }
                    }/>
                }
            }else{
                return  <CFRegistration onUserRegisteredSuccess={() => this.getMe()}/>
            }
        }else{
            return this.renderPreview();
        }
    }

    render(){
        return (
            <section id="container">
                {/*header start*/}
                <DLHeader />
                {/*header end*/}
                {/*main content start*/}
                <section id="main-content" style={{overflow:this.state.showSelectSignature ? 'hidden': 'auto'}}>
                    {this.renderContent()}
                </section>
                {/*main content end*/}
                {/*footer start*/}
                <DLFooter />
                <DLModalSendSignatureRequest
                    show={this.state.showSendSignatureModal}
                    onClickClose={this.handleModalSendSignature}
                    onRequestSignatories={(signatories) => this.onRequestSignatories(signatories)}
                    signatureType={this.state.selectedSignatoryType}
                />
                <DLSelectSignatureType show={this.state.showSelectSignature}
                                       onSelectSignature={(id) => this.onSelectSignature(id)}
                                       onClickClose={() => this.handleSelectSignature(false)} />
                <CreditsPopup
                    body={`Solicita firmar tu documento con la FIEL para que tenga validez en México. Cada documento firmado con FIEL utilizará 1 crédito.`}
                    title={'Firma con tu FIEL'}
                    confirmButtonText={'Solicitar Firmas'}
                    cancelButtonText={'Cancelar'}
                    ref={(ref) => this.fielMethodCreditsPopup = ref}
                    onConfirm={(data) => this.onConfirmFIELSignatures(data)}>
                </CreditsPopup>
                <CreditsPopup
                    body={`Una vez solicitado no podrás cambiar de tipo de firma`}
                    title={'¿Estás seguro(a)?'}
                    confirmButtonText={'Solicitar firmas'}
                    cancelButtonText={'Cancelar'}
                    ref={(ref) => this.otherMethodcreditsPopup = ref}
                    showCredits={false}
                    onConfirm={(data) => this.onConfirmFIELSignatures(data)}>
                </CreditsPopup>
            </section>
        )
    }
}

DocumentPreviewView.propTypes = {
    /** Document to be render. */
    doc: PropTypes.string,
};

DocumentPreviewView.defaultProps = {
    doc: '',
};

export default DocumentPreviewView;
