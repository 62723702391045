/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by América Mendoza <amendoza@nodeport.co> August 2020
*/

import React from 'react';
import PropTypes from 'prop-types';
import SignInComponent from "./auth/SignInComponent";
import Swal from "sweetalert2";

//FormData
const SignInData = require('../data/auth/sign-in.json');

class CFRegistration extends React.Component {
    onUserRegisteredSuccess(user) {
        const token = user.result.token;
        localStorage.setItem('NPAuthToken',token);
        let  onLoginSuccessUrl = localStorage.getItem('onLoginSuccessUrl');
        // onLoginSuccessUrl = onLoginSuccessUrl ? onLoginSuccessUrl : '/';
        localStorage.setItem('onLoginSuccessUrl','');
        // this.props.history.push({
        //     pathname:onLoginSuccessUrl
        // });
        this.props.onUserRegisteredSuccess();
    }

    onUserRegisteredFailed() {
        Swal.fire({
            icon: 'error',
            title: 'Usuario no registrado',
            text: 'No se ha podido completar el registro. Intenta más tarde',
        })
    }

    render(){
        return (
            <section className="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center feature-head my-4" style={{paddingBottom:'10px'}}>
                            <h1> Regístrate y obten tu primer documento gratis
                                <span className="logo" style={{marginTop:'0px',float:'none'}}> CON<span style={{color: "#419cd2"}}>TUFIRMA</span></span></h1>
                        </div>
                        <SignInComponent
                            loginForm={SignInData}
                            title={"¡Registrate aquí!"}
                            onUserRegisteredSuccess={ (user) => this.onUserRegisteredSuccess(user) }
                            onUserRegisteredFailed={ () => this.onUserRegisteredFailed() }
                            styleWrapper={{margin:'0px auto'}}
                        />
                    </div>
                </div>
            </section>
        )
    }
}
export default CFRegistration;
CFRegistration.propTypes = {
    onUserRegisteredSuccess   : PropTypes.func
}
CFRegistration.defaultProps = {
    onUserRegisteredSuccess   : () => {}
};

