/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Oscar Pena <opena@nodeport.co>, July 2020 &
* America Mendoza <amendoza@nodeport.co> August 2020
*/
import React from 'react';
import PropTypes from 'prop-types';

//import component
import DLErrorDisplay from "../DLErrorDisplay";

class DLHourSelector extends React.Component{
  constructor(p) {
    super(p);
    this.state = {
      values: hours(this.props.startHour,this.props.startMinutes, this.props.hoursIncrement, this.props.minutesIncrement)
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event){
    const fieldName = this.props.field.fieldName;
    const value     = event.target.value;

    this.props.onChange({[fieldName]:{
      field:this.props.field,
      value:value,
      }
    })
    this.setState({
      [fieldName]:value
      });
  }
  //Todo: remove comments
  // <div className="dl_text_container">
    // {this.props.field.description}
    // <select value={this.state.value} onChange={this.handleChange}>{
    //   this.state.values.map((n) =>{
    //     return(
    //         <option value={n}>{n}</option>
    //     )
    //   })
    // }
    // </select>
  // </div>
  render(){
    const name = this.props.field.fieldName;
    const field = this.props.field;
    const errors = this.props.errors;
      const lastValue=this.props.lastValue;
    //Todo: check style, font size seems to be a little bigger then the other inputs.
    return(
        <div className="form-group form-validation clearfix">
          <label className="col-lg-12 control-label " htmlFor={name}>{field.description} </label>
          <div className="col-lg-12">
                <select name={name}
                        className={errors.length > 0 ? 'custom-select error' : 'custom-select'}
                        value={lastValue}
                        onChange={(e) => this.handleChange(e)}>
                  {
                    this.state.values.map((n) =>{
                      return(
                          <option value={n}>{n}</option>
                      )
                    })
                  }
                </select>

                <DLErrorDisplay errors={errors}></DLErrorDisplay>
          </div>
        </div>
    )
  }
}

function hours(starHour, startMinutes, hoursIncremen, minutesIncrement){
  let hour = (starHour > 24 || starHour < 0) ? 0 : starHour;
  let i=0;
  let hoursArray=[];
  while (hour<24){
    let minute= (startMinutes > 60 || startMinutes < 0) ? 0 : startMinutes;
    while (minute<60){
      hoursArray[i]=fmtHour(hour,minute);
      i++;
      if(minutesIncrement>0)
        minute = minute+minutesIncrement;
      else
        break;
    }
    if(hoursIncremen>0)
      hour=hour + hoursIncremen;
    else
      break;
  }
  return hoursArray;
}
function fmtHour(hour, minute){
  let hourString = hour < 10 ? `0${hour}` : hour.toString();
  let minuteString = minute < 10 ? `0${minute}` : minute.toString();
  return `${hourString}:${minuteString}`;
}

export default DLHourSelector;
DLHourSelector.propTypes = {
    field               : PropTypes.object,
    starHour            : PropTypes.number,
    startMinutes        : PropTypes.number,
    hoursIncrement      : PropTypes.number,
    minutesIncrement    : PropTypes.number,
    errors              : PropTypes.array

};

DLHourSelector.defaultProps = {
    startHour             : 0,
    startMinutes          : 0,
    hoursIncrement        : 1,
    minutesIncrement      : 30,
    field                 : {
                              handleName:'',
                              type:'hour',
                              validator:'',
                              description:'',
                              fieldName:'',
                              defaultValue:''
    },
    onChange               : (change) => {},
    errors                 : []

};
