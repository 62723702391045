/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by America Mendoza <amendoza@nodeport.co> October 2020
*/

// Regular imports
import React from "react";

// Import styling
import '../style/bootstrap-reset.css'
import '../style/style.css'
import '../style/style-responsive.css'

// Import components
import DocuLegalApi from "../api/DocuLegalApi";
import BootstrapTable from 'react-bootstrap-table-next';
import moment from "moment";



class CFPaymentHistory extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            payments:[]
        }
        this.getHistory                 = this.getHistory.bind(this);
    }

    componentDidMount() {
        this.getHistory();
    }

    getHistory(){
        DocuLegalApi.getHistory().then((res) =>{
            this.setState({payments:res.payments})
        })
    }

    dateFormatter(date){
        return moment(date).locale('es').fromNow();
    }

    statusFormatter(status){
        return status ? 'Pagado' : 'Falló';
    }

    amountFormatter(amount){
            return (new Intl.NumberFormat('mx-MX', { style: 'currency', currency: 'MXN' }).format(amount));
    }
    emptyValue(){
        return (<p align={"center"}>Sin registro de pagos</p>)
    }
    render() {
        const columns = [
            {
                dataField: 'creditCardProviderResponse.id',
                text: 'ID de compra'
            },
            {
                dataField: 'created_at',
                formatter: this.dateFormatter,
                text: 'Fecha de compra',
                headerStyle: (column, colIndex) => {
                    return { width: '300px'};
                }
            },
            {
                dataField: 'item.credits',
                text: 'Documentos comprados'
            },
            {
                dataField: 'amount',
                formatter: this.amountFormatter,
                text: 'Precio'
            },
            {
                dataField: 'success',
                text: 'Estado de compra',
                formatter: this.statusFormatter,
            }
        ];
        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            Historial de pagos
                        </div>
                        <div className="card-body" style={{overflowX:'auto'}}>
                            <BootstrapTable
                                striped={true}
                                keyField='id'
                                data={ this.state.payments }
                                columns={ columns }
                                noDataIndication={this.emptyValue()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CFPaymentHistory;
