/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Oscar Pena <opena@nodeport.co> August 2020
*/

import React, { PureComponent } from "react";
import SignaturePad from "react-signature-canvas";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import ReactResizeDetector from 'react-resize-detector';

class DigitalSignature extends PureComponent {
    constructor(params) {
        super(params);
        this.state = {
            width: 600,
            height: 200,
            penColor: 'blue',
            open:false,
            padWidth:200,
            startingToSign:false
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleOnClickSave = this.handleOnClickSave.bind(this);
        this.handleOnClickClear = this.handleOnClickClear.bind(this);
        this.onWrapperSignaturePadResize = this.onWrapperSignaturePadResize.bind(this);
    }
    componentDidMount() {

    }
    onWrapperSignaturePadResize(w,h){
        this.setState({
            padWidth:w
        })
    }

    handleOnClickSave(){
        if(!this.sigPad.isEmpty()){
            localStorage.setItem(
                "signatureImage64",
                this.sigPad.toDataURL('image/png')
            );
            this.props.onClick();
        }
        else
        {
            Swal.fire({
                icon: "error",
                title: "Ingresa tu firma",
                text: "Firma en el recuadro y da click en guardar",
            });
        }

    }
    handleOnClickClear(){
        this.setState({startingToSign:false});
        this.sigPad.clear();
    }
    openModal() {
        this.setState({ open: true }, () =>{
        });
    }
    closeModal() {
        this.setState({ open: false });
    }
    showSig() {
        return(
            <div className={"container2"}>
                <div className={"sigContainer"}>
                    <SignaturePad ref={(ref) => {this.sigPad = ref}} width={100}
                                  canvasProps={{className: 'sigPad'}}
                                  penColor={this.state.penColor}/>
                </div>
            </div>
        )
    }
    render() {
        const hide = this.state.startingToSign ? 'hide-msg' : '';
        return (
            <div className={""}>
                {/*<div className={"card-header"}>Firma dentro del rectángulo</div>*/}
                <ReactResizeDetector handleWidth handleHeight onResize={this.onWrapperSignaturePadResize}>
                    <div className={"card-body dropzone-wrapper"} style={{backgroundColor:'white'}}>
                        <div className={`default-message ${hide}`} style={{width:this.state.padWidth}}><span>Firma dentro del rectángulo</span></div>

                        <SignaturePad
                            ref={(ref) => {this.sigPad = ref}}
                            canvasProps={{width: this.state.padWidth,height:200,className: 'dropzone'}}
                            penColor={this.state.penColor}
                            onBegin={() => this.setState({startingToSign: true})}
                        />


                    </div>
                </ReactResizeDetector>

                <div className={'d-flex justify-content-end mt-2'}>
                    <button className="btn btn-success mr-1" onClick={() => this.handleOnClickSave()}>Firmar</button>
                    <button className="btn btn-danger" onClick={() => this.handleOnClickClear()}>Borrar</button>
                </div>
            </div>
        );
    }
}
export default DigitalSignature;

// <div>
//     <button className="btn btn-primary btn-lg btn-block"
//             onClick={this.openModal}>
//         Firma aquí
//     </button>
//     <Modal show={this.state.open}
//            onHide={() => this.closeModal()}
//            size="lg"
//            centered
//            backdrop={false}
//     >
//         <Modal.Header closeButton>
//             <Modal.Title>Firma dentro del rectángulo</Modal.Title>
//         </Modal.Header>
//         <ReactResizeDetector handleWidth handleHeight onResize={this.onWrapperSignaturePadResize}>
//             <Modal.Body>
//                 <SignaturePad
//                     ref={(ref) => {this.sigPad = ref}}
//                     canvasProps={{width:this.state.padWidth,height:200,className: 'dropzone'}}
//                     penColor={this.state.penColor}
//                 />
//             </Modal.Body>
//         </ReactResizeDetector>
//         <Modal.Footer>
//             <button className="btn btn-success mr-1" onClick={() => this.handleOnClickSave()}>Firmar</button>
//             <button className="btn btn-danger" onClick={() => this.handleOnClickClear()}>Borrar</button>
//         </Modal.Footer>
//     </Modal>
// </div>

DigitalSignature.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    velocityFilterWeight: PropTypes.number,
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    minDistance: PropTypes.number,
    dotSize: PropTypes.number,
    penColor: PropTypes.string,
    throttle: PropTypes.number,
    open:PropTypes.bool,

}
DigitalSignature.defaultProps = {
    width: 600,
    height: 200,
    velocityFilterWeight: 0.5,
    minWidth: 0.5,
    maxWidth: 2.5,
    minDistance: 5,
    dotSize: 1,
    penColor: 'blue',
    throttle: 16,
    open:false,
};
