/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by América Mendoza <amendoza@nodeport.co> August 2020
*/

import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DocuLegalApi from "../api/DocuLegalApi";
import Swal from "sweetalert2";
import { Button, Collapse } from 'react-bootstrap';

class DLRequestValidationCode extends React.Component {
    constructor(p) {
        super(p);
        this.state = {
            code: '',
            phone: '',
            showSmsForm: false
        }
        this.sendValidationCode                 = this.sendValidationCode.bind(this);
        this.onChange                           = this.onChange.bind(this);
        this.requestValidationCode              = this.requestValidationCode.bind(this);

    }
    componentDidMount() {
        this.requestValidationCode();
    }

    requestValidationCode(method = 'email', phone = ''){
        if( method === 'sms' ){
            phone = `+52${phone}`
        }
        DocuLegalApi.requestValidation(method, phone).then((res) =>{
            if(res.data.success && method === 'sms'){
                Swal.fire(
                    "Código enviado",
                    `El código se ha reenviado al número ${this.state.phone}`,
                    "success"
                );
            }
        }).catch((err) => {
            // todo: do something
        });
    }

    onValidationSuccess(){
        this.props.onValidationSuccess();
    }

    sendValidationCode(){
        const code = this.state.code.trim();
        // send validation code
        if(code !== ''){
            DocuLegalApi.validateCode(code).then(res => {
                if(res.data.success){
                    this.props.onValidationSuccess();
                }
            }).catch((err) =>{
                Swal.fire(
                    "Error",
                    err.response.data.error_es,
                    "error"
                );
            });
        }else{
            Swal.fire(
                "Código inválido",
                "El código no puede ser vacío",
                "error"
            );
        }
    }

    onChange(e){
        let data = e.target;
        this.setState({[data.name] : data.value});
    }

    render(){
            return (
                    <div className="wrapper">
                        <div className="container">
                            <div className={"row mt-5"}>
                                <div className={"col-lg-12"}>
                                    <h3 className={'mb-4'}>Ingresa tu código de verificación</h3>
                                    <p>Tu cuenta no ha sido verificada. Ingresa el código que enviamos a tu correo para firmar tu documento.</p>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-lg-12 col-xl-12"}>
                                    <div className={"card"}>
                                        <div className={"card-body"}>
                                            <div className="form-group col-lg-12 col-xl-12">
                                                <p>Ingresa el código de verificación.</p>

                                                <input type="text"
                                                       name="code"
                                                       placeholder="Código de verificación"
                                                       className="form-control code-input"
                                                       onChange={(e) => this.onChange(e)}/>
                                                <button className="btn btn-lock" onClick={() => this.sendValidationCode()}>
                                                    <FontAwesomeIcon icon={'arrow-right'}/>
                                                </button>
                                            </div>

                                            <Button variant="link"
                                                    className={'btn-accordion'}
                                                    onClick={() => this.setState({showSmsForm: !this.state.showSmsForm})}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={this.state.showSmsForm}>
                                                    ¿No recibiste el correo? Enviar código de verificación por sms.
                                            </Button>
                                            <Collapse in={this.state.showSmsForm}>
                                                <div className="form-group col-lg-6 col-xl-6" id="example-collapse-text">
                                                    <input type="text"
                                                           name="phone"
                                                           placeholder="Ingresa el número de celular (10 dígitos)"
                                                           className="form-control code-input"
                                                           onChange={(e) => this.onChange(e)}
                                                           maxLength={10}
                                                           minLength={10}/>
                                                    <button className="btn btn-lock"
                                                            onClick={() => this.requestValidationCode('sms',this.state.phone)}>
                                                        <FontAwesomeIcon icon={'arrow-right'}/>
                                                    </button>
                                                </div>
                                            </Collapse>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            )
    }
}
export default DLRequestValidationCode;
DLRequestValidationCode.propTypes = {
    onValidationSuccess         : PropTypes.func,
}
DLRequestValidationCode.defaultProps = {
    onValidationSuccess         : () => {},
};

