/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Oscar Pena <opena@nodeport.co>, July 2020 &
* America Mendoza <amendoza@nodeport.co> August 2020
*/

import React from 'react';
import PropTypes from 'prop-types';

//import component
import DLErrorDisplay from "../DLErrorDisplay";

class DLId extends React.Component{
    constructor(p) {
        super(p);
        this.state = {
          //values :this.props.field.defaultValue
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event){
      const fieldName = this.props.field.fieldName;
      const value     = event.target.value;
      let type = '';
      let val = '';
      if(event.target.name === "idType" )

          localStorage.setItem(
              "type", value
          );
      else
          localStorage.setItem(
              "value", value
          );
      type = localStorage.getItem("type");
      val = localStorage.getItem("value");
      console.log(`${type} con número de folio ${val}`);
      this.props.onChange({[fieldName]:{
        field:this.props.field,
        value:`${type} con número de folio ${val}`
      }})
      this.setState({
        [fieldName]:value
        }, () =>{
      });
    }

    //Todo: Check if we can unify the DLId
    render(){
        const name = this.props.field.fieldName;
        const field = this.props.field;
        const errors = this.props.errors;
        const lasttypeValue = getType(this.props.lastValue);
        const lastidValue = getid(this.props.lastValue);
        //Todo: check style, font size seems to be a little bigger then the other inputs.
        return(
            <div className="form-group form-validation clearfix">
                <label className="col-lg-12 control-label " htmlFor={name}>{field.description} </label>
                <div className="col-lg-12">
                    <select name={"idType"}
                            className={errors.length > 0 ? 'custom-select error' : 'custom-select'}
                            value={lasttypeValue}
                            onChange={(e) => this.handleChange(e)}>
                        {
                            field.defaultValue.map((n) =>{
                                return(
                                    <option value={n}>{n}</option>
                                )
                            })
                        }
                    </select>
                    <label className="col-lg-12 control-label " htmlFor={name}>Número de identificación</label>
                    <input className={errors.length > 0 ? `${this.props.customClass.classInput} error` : this.props.customClass.classInput}
                           id={name}
                           name={"idVal"}
                           type="text"
                           value={lastidValue}
                           onChange={(e) => this.handleChange(e)}
                           placeholder={this.props.field.placeholder}
                    />
                    <DLErrorDisplay errors={errors}></DLErrorDisplay>
                </div>
            </div>
        )
      }
    }
function getType(lastValue){
    const val = ["INE", "Cartilla Militar", "Cédula Profesional", "Pasaporte"];
    let pos=0;
    for(let i=0;i<val.length;i++){
        if(lastValue.includes(val[i]))
            pos=i;
    }
    return val[pos];
}


function getid(lastValue){
    const array = lastValue.split(" ");
    const  length= array.length
    return array[length-1];
}
export default DLId;
DLId.propTypes = {
    field   : PropTypes.object,
    errors  : PropTypes.array
};

DLId.defaultProps = {
    field   :  {
                  handleName:'',
                  type:'text-selector',
                  validator:'',
                  description:'',
                  fieldName:'',
                  defaultValue:[""]
      },
     customClass:        {
        classWrapper        : 'form-group form-validation clearfix',
        classLabel          : 'col-lg-12 control-label',
        classWrapperInput   : 'col-lg-12',
        classInput          : 'form-control',
    },
    onChange : (change) => {},
    errors   : []
};
