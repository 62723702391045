/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by América Mendoza <amendoza@nodeport.co> August 2020
*/

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DocuLegalApi from '../api/DocuLegalApi';
import DLCreateAccountComponent from "./auth/DLCreateAccountComponent";
import { Link } from "react-router-dom";
import DLIf from "./render/DLIf";
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
class DLDocumentsGridRow extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            showCreateAccountPopup:false
        }
        this.goToLogin = this.goToLogin.bind(this);
        this.goToSignIn  = this.goToSignIn.bind(this);
        this.redirectToDocument = this.redirectToDocument.bind(this);
    }

    goToLogin(){
        window.location.href = "/login";
    }
    
    goToSignIn(){
        window.location.href = "/sign-in";
    }

    redirectToDocument(url){
        DocuLegalApi.getMe().then((u) =>{
            if(u.success){
                window.location.href = url;
            }else{
                this.handleCreateAccountPopup(true);
            }
        }).catch((err) =>{
            console.log(err)
            this.handleCreateAccountPopup(true);
        })
    }

    handleCreateAccountPopup(showCreateAccountPopup){
        this.setState({
            showCreateAccountPopup: showCreateAccountPopup
        })
    }

    renderActionButtons(document){
        return(
            <DLIf if={document.url !== ''}>
                    <button className="btn btn-link btn-sm float-right" onClick={() => this.redirectToDocument("/documents/"+document.url)}>Crear</button>
            </DLIf>
        )
    }

    renderDescription(document){
        return (
            <DLIf if={document.description !== ''}>
                <ResponsiveEllipsis
                    text={document.description}
                    maxLine='2'
                    ellipsis='...'
                    trimRight
                    basedOn='letters'
                    style={{height:50}}
                    className="mt-2"
                />
            </DLIf>
        )
    }

    renderCategory(document){
        let badgeClass;
        let icon;
        const category = document.category ? document.category : 'OTRO';

        switch(category) {
            case 'pyme':
                badgeClass = 'badge-primary'
                icon = 'building';
                break;
            case 'personal':
                badgeClass = 'badge-success'
                icon = 'user-tie';
                break;
            default:
                badgeClass = 'badge-warning'
                icon = 'file-contract';
        }
        return(
            <span className={`badge ${badgeClass} text-center text-uppercase`}>
                <FontAwesomeIcon
                    className="mr-auto ml-auto"
                    color="white"
                    icon={icon}/> {category}
            </span>
        )
    }

    render() {
        const document = this.props.document;
        return (
            <div className="col-md-4 col-lg-4 col-xlg-3">
                <div className='card '>
                    <div className='card-header'><h5 className='mb-0' style={{overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{document.name}</h5></div>
                    <div className='card-body align-items-start' style={{height:150,overflow:"hidden"}}>
                        {this.renderCategory(document)}
                        {this.renderDescription(document)}
                        {this.renderActionButtons(document)}
                    </div>
                </div>
                <DLCreateAccountComponent
                    onLogin={() => this.goToLogin()}
                    onSignup={() => this.goToSignIn()}
                    onHide={() => this.handleCreateAccountPopup(false)}
                    show={this.state.showCreateAccountPopup}
                ></DLCreateAccountComponent>
            </div>
        )
    }
}

DLDocumentsGridRow.propTypes = {
    /** Document to be render. */
    document: PropTypes.object,
    /** Callback when the document is clicked */
    onClickDocument: PropTypes.func,
};

DLDocumentsGridRow.defaultProps = {
    document: {},
    onClickDocument: () => { },
};

export default DLDocumentsGridRow;
