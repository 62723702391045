/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by America Mendoza <amendoza@nodeport.co>
*/
//imports
import React from "react";
import PropTypes from 'prop-types';
import DocuLegalApi from "../api/DocuLegalApi";

//import css
import '../style/bootstrap-reset.css'

//import components
import DLHeader from "../components/DLHeader";
import DLFooter from "../components/DLFooter";
import DLIf from "../components/render/DLIf";
import DLElse from "../components/render/DLElse";


class AfterSignatureView extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            authorized:false
        }
        this.getMe            = this.getMe.bind(this);
        this.markFIELAsSigned = this.markFIELAsSigned.bind(this);
        const query           = new URLSearchParams(this.props.location.search);
        this.documentId       = this.props.match.params.id;
        this.documentType     = this.props.match.params.documentType;
        this.widgetId         = query.get('widgetId');
    }
    componentDidMount(){
        this.getMe();
        this.markFIELAsSigned();
    }
    markFIELAsSigned(){
        if(this.widgetId){
            DocuLegalApi.markFIELAsSigned(this.documentId,this.widgetId).then((r) =>{
                console.log(r)
            }).catch((ex) =>{
                console.log(ex)
            })
        }
    }
    getMe(){
        DocuLegalApi.getMe().then((u) =>{
            if(u.success){
                this.setState({
                    authorized:true,
                });
            }
        }).catch((err) =>{
        })
    }
    renderMsg(){
        return(
            <DLIf if={this.state.authorized === true}>
                <p>
                    Una vez que todos hayan firmado. Recibirás por correo una copia firmada de tu documento.<br/>
                    <a style={{textDecoration:'underline'}}
                                             className={"btn-link"}
                                             href={'/documents'}>Ir a mi cuentra</a>.
                </p>
                <DLElse>
                    <p>
                        Una vez que todos hayan firmado. Recibirás por correo una copia firmada de tu documento.<br/>
                        <a style={{textDecoration:'underline'}}
                                                 className={"btn-link"}
                                                 href={'/sign-in'}>crear una cuenta</a>.
                    </p>
                </DLElse>
            </DLIf>
        )
    }
    render(){
        return (
            <section id="container">
                {/*header start*/}
                <DLHeader />
                {/*header end*/}
                {/*main content start*/}
                <section id="main-content">
                    <div className="wrapper">
                        <div className="container">
                            <div className={'row'}>
                                <div className="col-lg-12 my-4">
                                    <div className={"card"}>
                                        <div className={"card-body  text-center feature-head"}>
                                            <h1 className={'mt-2 mb-4'}>Gracias por generar y firmar tus documentos
                                                <span className="logo"
                                                      style={{marginTop:'0px',float:'none'}}> CON<span style={{color: "#419cd2"}}>TUFIRMA</span>
                                                </span>
                                            </h1>
                                            {this.renderMsg()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*main content end*/}
                {/*footer start*/}
                <DLFooter simple={true}/>
            </section>
        )
    }
}

AfterSignatureView.propTypes = {
};

AfterSignatureView.defaultProps = {
};

export default AfterSignatureView;
