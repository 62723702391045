/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Oscar Pena <opena@nodeport.co> July 2020
* America Mendoza <amendoza@nodeport.co> August 2020
*/
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';

//import css
import "react-datepicker/dist/react-datepicker.css";

//imports components
import DLErrorDisplay from "../DLErrorDisplay";
import DLIf from "../render/DLIf";
registerLocale('es', es)

class DLCalendar extends React.Component{
  constructor(params) {
  super(params);
  this.state = {
      startDate: new Date(),
  };
  this.handleChange = this.handleChange.bind(this);
  }

  handleChange = date =>{
    const dateValue = ftmDate(date, this.props.field.format,
                              this.props.field.onlyTime, this.props.field.onlyDate);
    const fieldName = this.props.field.fieldName;

    this.setState({startDate:date});

    this.props.onChange({[fieldName]:
            {
              field:this.props.field,
              value:dateValue
            }
      }
    )
  }
  render(){
      const field     = this.props.field;
      const errors    = this.props.errors;
      const lastValue = this.props.lastValue;
      const onlyTime =  field.onlyTime;
      const onlyDate =  field.onlyDate;

      const selectDate = reftmDate(lastValue,this.props.field.format,onlyTime, onlyDate)
      if(onlyDate) {
          return (
              <div className="form-group form-validation clearfix">
                  <label className="col-lg-12 control-label ">{field.description} </label>
                  <div className="col-lg-12">
                      <DatePicker selected={selectDate}
                                  onChange={this.handleChange}
                                  dateFormat={"dd/MM/yyyy"}
                                  locale="es"
                                  showYearDropdown
                                  timeCaption="time"
                                  dateFormatCalendar="MMMM"
                                  yearDropdownItemNumber={50}
                                  scrollableYearDropdown
                                  className={errors.length > 0 ? "form-control error" : "form-control"}/>

                      <DLErrorDisplay errors={errors}></DLErrorDisplay>

                  </div>
              </div>
          )
      }
      if(onlyTime){
          return (
              <div className="form-group form-validation clearfix">
                  <label className="col-lg-12 control-label ">{field.description} </label>
                  <div className="col-lg-12">
                      <DatePicker selected={selectDate}
                                  onChange={this.handleChange}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={1}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className={errors.length > 0 ? "form-control error" : "form-control"}/>

                      <DLErrorDisplay errors={errors}></DLErrorDisplay>

                  </div>
              </div>
          )
      }
      else{
          return (
              <div className="form-group form-validation clearfix">
                  <label className="col-lg-12 control-label ">{field.description} </label>
                  <div className="col-lg-12">
                      <DatePicker selected={selectDate}
                                  onChange={this.handleChange}
                                  dateFormat={"dd/MM/yyyy h:mm aa"}
                                  locale="es"
                                  showYearDropdown
                                  showTimeSelect
                                  timeCaption="time"
                                  timeFormat="HH:mm"
                                  dateFormatCalendar="MMMM"
                                  yearDropdownItemNumber={50}
                                  scrollableYearDropdown
                                  className={errors.length > 0 ? "form-control error" : "form-control"}/>

                      <DLErrorDisplay errors={errors}></DLErrorDisplay>

                  </div>
              </div>
          )
      }
  }
}

function ftmDate(date,dateFormat, time, onlyDate){
    const day = date.getDate();
    const month= date.getMonth();
    const year= date.getFullYear();
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const months=['Enero','Febrero','Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre','Octubre','Noviembre','Diciembre'];
    let datereturn='';
    if(time)
    {
        return `${hour}:${minutes}`
    }
    else {
        switch (dateFormat) {
            case "day":
                datereturn = `${day} de ${months[month]} de ${year}`;
            case "days":
                datereturn =`${day} dias del mes de ${months[month]} de ${year}`;
            case "/":
                datereturn = `${day}/${months[month]}/${year}`;
            case "-":
                datereturn = `${day}-${months[month]}-${year}`;
            default:
                datereturn = `${day} de ${months[month]} de ${year}`;
        }
        if(!onlyDate)
        {
            datereturn = `${datereturn} siendo las ${hour}:${minutes} hrs`
        }
        return datereturn;
    }

}

function reftmDate(dateString,dateFormat, time, onlyDate){
    let date = new Date();
    let array=[];
    const months=['Enero','Febrero','Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre','Octubre','Noviembre','Diciembre'];
    if (time && dateString){
        date.setHours(dateString.split(":")[0]);
        date.setMinutes(dateString.split(":")[1]);
    }
    else {
        if (dateString) {
            for (let i = 0; i < months.length; i++) {
                if (dateString.includes(months[i]))
                    date.setMonth(i);
            }
                switch (dateFormat) {
                    case "/":
                        array = dateString.split("/");
                    case "-":
                        array = dateString.split("-");
                    default:
                        array = dateString.split(" ");
                }
                date.setDate(array[0]);
            if(onlyDate) {
                date.setFullYear(array[(array.length - 1)]);
            }
            else
            {
                date.setFullYear(array[(array.length - 5)]);
                date.setHours(array[(array.length - 2)].split(":")[0]);
                date.setMinutes(array[(array.length - 2)].split(":")[1]);
            }
        }
    }
    return date;
}


export default DLCalendar;
DLCalendar.propTypes = {
    field    : PropTypes.object,
    onChange : PropTypes.func,
    errors   : PropTypes.array
};

DLCalendar.defaultProps = {
    field:{
        handleName:'',
        type:'text',
        validator:'',
        description:'',
        fieldName:'',
        defaultValue:''
    },
    onChange : (change) => {},
    errors: []
};
