/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Oscar Pena <opena@nodeport.co> November 2020
*/
import React from 'react';

//import components
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";


const AdminSidebar = ({activeUsers,inactiveUsers}) => {
        return(
            <div id="sidebar"  className="nav-collapse ">
                {/* sidebar menu start*/}
                <ul className="sidebar-menu" id="nav-accordion">
                    <li>
                        <a className = {activeUsers?'active' : ''} href={`/admin/usuarios/`}>
                            <FontAwesomeIcon  icon='users' size={'lg'}/>
                            <span style={{marginLeft:'6px'}}>Usuarios</span>
                        </a>
                    </li>
                    <li>
                        <a className={inactiveUsers?'active' : ''} href={`/admin/usuarios-bloqueados/`}>
                            <FontAwesomeIcon  icon='users' size={'lg'}/>
                            <span style={{marginLeft:'6px'}}>Desbloquear Usuarios</span>
                        </a>
                    </li>
                </ul>
                {/* sidebar menu end*/}
            </div>
        )
}
AdminSidebar.propTypes = {
    activeUsers: PropTypes.bool,
    inactiveUsers: PropTypes.bool,

}
AdminSidebar.defaultProps = {
    activeUsers: false,
    inactiveUsers: false,

}
export default AdminSidebar;
