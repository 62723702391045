/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Darien Miranda <dmiranda@nodeport.co> & Oscar Pena <opena@nodeport.co> July 2020
*/
import React from 'react';
import DLText from "./fields/DLText";
import DLNumberSelector from "./fields/DLNumberSelector";
import DLTextSelector from "./fields/DLTextSelector";
import DLTextArea from "./fields/DLTextArea";
import DLCalendar from "./fields/DLCalendar";
import DLHourSelector from "./fields/DLHourSelector";
import DLWeekDays from "./fields/DLWeekDays";
import DLMultipleInput from "./fields/DLMultipleInput";
import DLPassword from "./fields/DLPassword";
import DLId from "./fields/DLId";
import DLIf from "./render/DLIf";
import DLElse from "./render/DLElse";
import PropTypes from 'prop-types';
import DLSwitch from "./fields/DLSwitch";
class ComponentResolver extends React.Component{
    constructor(p) {
        super(p);
        this.COMPONENTS = [
            {
                type:'text',
                component: DLText
            },
            {
                type:'number-selector',
                component: DLNumberSelector
            },
            {
                type:'text-selector',
                component: DLTextSelector
            },
            {
                type:'textarea',
                component: DLTextArea
            },
            {
                type:'calendar',
                component: DLCalendar
            },
            {
                type:'hour',
                component: DLHourSelector
            },
            {
                type:'week',
                component: DLWeekDays
            },
            {
                type:'multiple',
                component: DLMultipleInput
            },
            {
                type:'password',
                component: DLPassword
            },
            {
                type:'idValue',
                component: DLId
            },
            {
                type: 'switch',
                component: DLSwitch
            }



        ]
    }
    resolve(){
        const Component = this.COMPONENTS.find( c => c.type === this.props.field.type).component;
        return(
            <DLIf if={ Object.entries(this.props.customClass).length === 0 }>
                <Component  field={this.props.field} 
                            onChange={this.props.onChange} 
                            isValid={this.props.isValid} 
                            errors={this.props.errors} 
                            lastValue={this.props.lastValue}>
                </Component>

                <DLElse>
                
                    <Component  field={this.props.field} 
                                onChange={this.props.onChange} 
                                isValid={this.props.isValid} 
                                errors={this.props.errors} 
                                lastValue={this.props.lastValue}
                                customClass={this.props.customClass}>
                    </Component>

                </DLElse>

            </DLIf>
        )
    }
    render(){
      return(
          this.resolve()
      )
    }
}
export default ComponentResolver;
ComponentResolver.propTypes = {
    field: PropTypes.object,
    customClass: PropTypes.object,
    onChange   : PropTypes.func,
    isValid    : PropTypes.bool,
    errors     : PropTypes.array,
    lastValue  : PropTypes.string
};

ComponentResolver.defaultProps = {
    fields     : {},
    customClass: {},
    onChange   : (change) => {},
    isValid    : true,
    errors     : [],
    lastValue  : ''
};
