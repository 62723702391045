/* Copyright (C) Startuplab MX. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Israel Arellano <Isra_darrell@startuplab.mx> August 2020
*/

import React from "react";
import PropTypes from 'prop-types';
import DLForm from "../DLForm";
import { Link } from "react-router-dom";
import DocuLegalApi from "../../api/DocuLegalApi";
import { isRegisterComplete } from "../validators/utils.js"
import Swal from 'sweetalert2'

class SignInComponent extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            currentForm: [],
            fields: {}
        }
        /* Use ref to access DLForm state*/
        this.DLForm = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        return { currentForm: props.loginForm.forms[0] }
    }

    onChange(change) {
        let key = Object.keys(change)[0];
        let fields = this.state.fields;
        fields[key] = change[key]
        this.setState({
            fields
        })
    }

    /* Validate form with validations in DLForm*/
    isValidForm() {
        let fields = this.DLForm.state;
        if (isRegisterComplete(fields.validations)) {
            fields = fields.validations;
            if (fields.name.isValid && fields.last_name.isValid && fields.email.isValid && fields.password.isValid) {
                return true
            }
        }

        return false

    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.createUser();
        }
    };

    createUser() {
        let fields = this.state.fields;
        if (this.isValidForm()) {
            DocuLegalApi.createUser(fields.name.value, fields.last_name.value, fields.email.value, fields.password.value).then((res) => {
                if (res.success) {
                    this.props.onUserRegisteredSuccess(res)
                } else {
                    this.props.onUserRegisteredFailed(res)
                }
            }).catch((err) => {
            })
        } else {
            Swal.fire({
                icon: 'error',
                text: 'Revisa tus datos, te recordamos que debes de llenar todos los campos.',
                confirmButtonText:'Volver a intentar'
            })
        }
    }

    render() {
        return (
            <div className={this.props.classNameWrapper} style={this.props.styleWrapper}>
                <h2 className="form-signin-heading">{this.props.title}</h2>
                <div className="login-wrap">
                    <div className="col-lg-12">
                        <p className="text-left">¿Ya estás registrado?
                            <Link className="pl-2"
                                  to={{ pathname: '/login', params: { doc: document.doc } }}>
                                  Inicia sesión
                            </Link>
                        </p>
                    </div>
                    <div onKeyPress={(event) => this.handleKeyPress(event)}>
                        <DLForm onChange={(change) => this.onChange(change)}
                            form={this.state.currentForm}
                            ref={(DLForm) => { this.DLForm = DLForm }}></DLForm>
                    </div>
                    <div className="col-lg-12">
                        <button className="btn btn-lg btn-danger btn-block" onClick={() => this.createUser()}>Registrarme</button>
                    </div>
                </div>
            </div>
        )
    }
}

SignInComponent.propTypes = {
    /** Login Form */
    loginForm: PropTypes.array,
    /** Title Form*/
    title: PropTypes.string,
    /** Success function*/
    onUserRegisteredSuccess: PropTypes.func,
    /** Failed function*/
    onUserRegisteredFailed: PropTypes.func,
    /** Class name wrapper*/
    classNameWrapper: PropTypes.func,
    /** Style wrapper*/
    styleWrapper: PropTypes.object,
}
SignInComponent.defaultProps = {
    loginForm: [],
    title: '',
    onUserRegisteredSuccess: (user) => { },
    onUserRegisteredFailed: (res) => { },
    classNameWrapper: 'form-signin mb-5',
    styleWrapper: {},
};

export default SignInComponent;
