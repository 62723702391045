const id = 'email';
let defaultProps = {
}
const extractProps = function(validator){
    const props = validator.props || {};
    const keys = Object.keys(props);
    for (const key in keys){
        defaultProps[keys[key]] = props[keys[key]];
    }
}
const extractValue = function(field){
    return field.value || '';
}
const validateEmail = function (field,validator){
    extractProps(validator)
    const value = extractValue(field).trim();

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid  = re.test(String(value).toLowerCase());
    return {
        isValid:isValid,
        message: isValid ? '' :`El campo ${field.field.description.toLowerCase()} no es un email válido`
    }
}
module.exports = {
    id:id,
    validate:validateEmail
}
