/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Oscar Pena <opnea@nodeport.co> November 2020
*/

// Regular imports
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Doughnut} from 'react-chartjs-2';
import DocuLegalApi from "../../../api/DocuLegalApi";
import Swal from "sweetalert2";
import {withRouter} from "react-router-dom"
// Import styling
import '../../../style/bootstrap-reset.css'
import '../../../style/style.css'
import '../../../style/style-responsive.css'
import DLIf from "../../../components/render/DLIf";
import DLElse from "../../../components/render/DLElse";
import DLHeader from "../../../components/DLHeader";
import AdminSidebar from "../../../components/AdminSidebar";


class UserProfile extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            user:{

                name: '',
                last_name: '',
                email: '',
            },
            quota:0,
            documentsCreated:0,
            data: {
                labels: [
                    'Documentos Generados',
                    'Documentos Restantes'
                ],
                datasets: [{
                    data: [0, 0],
                    backgroundColor: [
                        '#87a4d8',
                        '#80d6a3'
                    ],
                    hoverBackgroundColor: [
                        '#87a4d8',
                        '#80d6a3',
                    ]
                }]
            },
            documents:[],
            editProfile:false,
            id:'',
            name:'',
            last_name:'',
            authorized:false
        }
        this.getUser               = this.getUser.bind(this);
        this.getQuota            = this.getQuota.bind(this);
        this.getDocuments        = this.getDocuments.bind(this);
        this.requestRestPassword = this.requestRestPassword.bind(this);
        this.updateUser          = this.updateUser.bind(this);
        this.resetValues         = this.resetValues.bind(this);
    }

    componentDidMount() {
        const userId = this.props.match.params.id;
        this.getUser(userId);
        this.getQuota(userId);
        this.getDocuments();
        DocuLegalApi.getMe().then((res) => {
            if (res.success) {
                const roles = res.user.roles ? res.user.roles : [];
                if (roles.includes('admin')) {
                    this.setState({
                        authorized: true
                    })
                }
            }
        });
    }

    getUser(userID){
        DocuLegalApi.getUser(userID).then((u) =>{
            if(u.success){
                this.setState({
                    user:u.user,
                    name:u.user.name,
                    last_name:u.user.last_name,
                    id:u.user._id
                });
            }
        }).catch((err) =>{
            console.log(err);
        })
    }

    resetValues(){
        this.setState({
            name:this.state.user.name,
            last_name:this.state.user.last_name
        });
    }

    getQuota(userId){
        DocuLegalApi.getUserQuota(userId).then((res) =>{
            if(res.success){
                this.setState({
                    quota:res.quota,
                    documentsCreated:res.documentsCreated,
                    data: {
                        labels: [
                            'Documentos Generados',
                            'Documentos Restantes'
                        ],
                        datasets: [{
                            data: [res.documentsCreated, res.quota],
                            backgroundColor: [
                                '#87a4d8',
                                '#80d6a3'
                            ],
                            hoverBackgroundColor: [
                                '#87a4d8',
                                '#80d6a3',
                            ]
                        }]
                    }
                })
            }
        }).catch((err) =>{
            console.log(err)
        })
    }

    getDocuments(){
        DocuLegalApi.getTemplates().then((templates) =>{
            this.setState({
                documents:templates.templates
            })
        })
        /*console.log("ta qui");
        console.log(this.props.match.params.id);
        DocuLegalApi.getDocsInfo(this.props.match.params.id).then((res) =>{
            console.log("algo saldra");
            console.log(res);
        })*/
    }

    getBadgeClass(category){
        switch (category) {
            case 'pyme':
                return 'badge-primary';
            case 'personal':
                return 'badge-success';
            default:
                return 'badge-warning';
        }
    }

    onChange(e){
        let data = e.target;
        this.setState({[data.name] : data.value});
    }

    updateUser() {
        const idUser2 = this.state.id;
        const name = this.state.name;
        const last_name = this.state.last_name;
        //console.log(idUser2);
        if( (name.trim() !== '') && (last_name.trim() !== '') ){
            DocuLegalApi.adminUpdateUser(idUser2,name,last_name).then((res) => {
                //console.log(res);
                if(res.success){
                    this.getUser(idUser2);
                    this.setState({editProfile:false});
                    Swal.fire({
                        title: 'Actualización de datos',
                        text: 'Se actualizó correctamente la información del usuario.',
                        icon: 'success',
                    })
                }
                else {
                    Swal.fire({
                        title: 'Actualización de datos',
                        text: 'Ocurrio un problema, intentelo nuevamente.',
                        icon: 'error',
                    })
                }
            });
        }
    }

    render() {
        const documents = this.state.documents;
        if(this.state.authorized === false){
            return null;
        }
        return (
            <section id="container" className='light-sidebar-nav'>
                {/*header start*/}
                <DLHeader />
                {/*header end*/}
                {/*sidebar start*/}
                <aside>
                    <AdminSidebar/>
                </aside>
                {/*sidebar end*/}
                <section id="main-content-profile">
                    <section className="wrapper" style={{maxWidth: '1200px'}}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        Información de usario
                                    </div>
                                    <div className="card-body">
                                        <div className="bio-row">
                                            <DLIf if={this.state.editProfile}>
                                                <p><span>Nombre </span>
                                                    <input className={'form-control'}
                                                           name='name'
                                                           value={this.state.name}
                                                           placeholder={'Nombre'}
                                                           onChange={(e) => this.onChange(e)}/>
                                                </p>
                                                <DLElse>
                                                    <p><span>Nombre </span><b> {this.state.user.name}</b></p>
                                                </DLElse>
                                            </DLIf>
                                        </div>
                                        <div className="bio-row">
                                            <DLIf if={this.state.editProfile}>
                                                <p><span>Apellido </span>
                                                    <input className={'form-control'}
                                                           name='last_name'
                                                           value={this.state.last_name}
                                                           placeholder={'Apellido'}
                                                           onChange={(e) => this.onChange(e)}/>
                                                </p>
                                                <DLElse>
                                                    <p><span>Apellido </span><b> {this.state.user.last_name}</b></p>
                                                </DLElse>
                                            </DLIf>
                                        </div>
                                        <div className="bio-row">
                                            <p><span>Email </span><b> {this.state.user.email}</b></p>
                                        </div>
                                        <div className="bio-row">
                                            <p><span>Documentos restantes </span><b> {this.state.quota}</b></p>
                                        </div>
                                        <DLIf if={this.state.editProfile}>
                                            <div className={'float-right'}>
                                                <button className='btn btn-success btn-sm mr-1'
                                                        onClick={() => this.updateUser()}>
                                                    Guardar
                                                </button>
                                                <button  className='btn btn-danger btn-sm'
                                                         onClick={() => {
                                                             this.setState({editProfile: false});
                                                             this.resetValues();
                                                         }}>
                                                    Cancelar
                                                </button>
                                            </div>
                                        </DLIf>
                                    </div>
                                </div>
                            </div>

                            <div  className="col-lg-6">
                                <div className="card">
                                    <div className="card-header">
                                        Documentos restantes
                                    </div>
                                    <div className="card-body">
                                        <Doughnut data={this.state.data}/>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="col-lg-2"></div>*/}
                            <div  className="col-lg-6">
                                <div className="card">
                                    <div className="card-header">
                                        Crear documento
                                    </div>
                                    <div className="list-group">
                                        { documents.map( doc => {
                                            const badgeClass = this.getBadgeClass(doc.category);
                                            return(
                                                <a className="list-group-item" href={`/documents/${doc.url}`}>
                                                    {doc.name} <span className={`float-right badge ${badgeClass} text-uppercase`}>{doc.category}</span>
                                                </a>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className={'float-right'}>
                                            <button className='btn btn-info btn-sm mr-1'
                                                    onClick={() => this.setState({editProfile:true})}>
                                                <FontAwesomeIcon  icon='pencil-alt'/> Editar
                                            </button>
                                            <button  className='btn btn-warning btn-sm' onClick={() => this.requestRestPassword()}>
                                                <FontAwesomeIcon  icon='key' /> Reestablecer contraseña
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </section>
            </section>
        )
    }

    requestRestPassword() {
        DocuLegalApi.requestPasswordReset(this.state.user.email).then((r) =>{
            //console.log(this.state.user.email);
            if(r.data.success){
                Swal.fire({
                    title: 'Envío existoso',
                    text: `Se han enviado las instrucciones para reestablecer la contraseña a ${this.state.user.email}`,
                    icon: 'success',
                })
            }

        }).catch((err) =>{
            Swal.fire({
                title: 'Envío no existoso',
                text: `Se ha producido un error`,
                icon: 'error',
            })
        })
    }
}
export default withRouter(UserProfile);
