/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by America Mendoza <amendoza@nodeport.co> October 2020
*/

// Regular imports
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DocuLegalApi from "../api/DocuLegalApi";
import Swal from "sweetalert2";

// Import styling
import '../style/bootstrap-reset.css'
import '../style/style.css'
import '../style/style-responsive.css'
import DLIf from "./render/DLIf";
import DLElse from "./render/DLElse";


class CFProfile extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            me:{
                name: '',
                last_name: '',
                email: '',
            },
            quota:0,
            documentsCreated:0,
            purchaseHistory:[],
            documents:[],
            editProfile:false,
            name:'',
            last_name:''
        }
        this.getMe               = this.getMe.bind(this);
        this.getQuota            = this.getQuota.bind(this);
        this.getDocuments        = this.getDocuments.bind(this);
        this.requestRestPassword = this.requestRestPassword.bind(this);
        this.updateUser          = this.updateUser.bind(this);
        this.resetValues         = this.resetValues.bind(this);
    }

    componentDidMount() {
        this.getMe();
        this.getQuota();
        this.getDocuments();
    }

    getMe(){
        DocuLegalApi.getMe().then((u) =>{
            if(u.success){
                this.setState({
                    me:u.user,
                    name:u.user.name,
                    last_name:u.user.last_name
                });
            }
        }).catch((err) =>{
            console.log(err);
        })
    }

    resetValues(){
        this.setState({
            name:this.state.me.name,
            last_name:this.state.me.last_name
        });
    }

    getQuota(){
        DocuLegalApi.getQuota().then((res) =>{
            if(res.success){
                this.setState({
                    quota:res.quota,
                    documentsCreated:res.documentsCreated,
                    purchaseHistory:res.purchaseHistory,
                })
            }
        }).catch((err) =>{
            console.log(err)
        })
    }

    getDocuments(){
        DocuLegalApi.getTemplates().then((templates) =>{
            this.setState({
                documents:templates.templates
            })
        })
    }

    getBadgeClass(category){
        switch (category) {
            case 'pyme':
                return 'badge-primary';
            case 'personal':
                return 'badge-success';
            default:
                return 'badge-warning';
        }
    }

    onChange(e){
        let data = e.target;
        this.setState({[data.name] : data.value});
    }

    updateUser(){
        const name = this.state.name;
        const last_name = this.state.last_name;
        if( (name.trim() !== '') && (last_name.trim() !== '') ){
            DocuLegalApi.updateUser(name,last_name).then((res) => {
                if(res.data.success){
                    this.getMe();
                    this.setState({editProfile:false});
                }
            });
        }
    }

    render() {
        const documents = this.state.documents;
        const _purchaseHistory = this.state.purchaseHistory ? [...this.state.purchaseHistory] : [];
        const purchaseHistory = _purchaseHistory.reverse().slice(0,8);
        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            Mi cuenta
                        </div>
                        <div className="card-body">
                            <div className="bio-row">
                                <DLIf if={this.state.editProfile}>
                                    <p><span>Nombre </span>
                                        <input className={'form-control'}
                                               name='name'
                                               value={this.state.name}
                                               placeholder={'Nombre'}
                                               onChange={(e) => this.onChange(e)}/>
                                    </p>
                                    <DLElse>
                                        <p><span>Nombre </span><b> {this.state.me.name}</b></p>
                                    </DLElse>
                                </DLIf>
                            </div>
                            <div className="bio-row">
                                <DLIf if={this.state.editProfile}>
                                    <p><span>Apellido </span>
                                        <input className={'form-control'}
                                               name='last_name'
                                               value={this.state.last_name}
                                               placeholder={'Apellido'}
                                               onChange={(e) => this.onChange(e)}/>
                                    </p>
                                    <DLElse>
                                        <p><span>Apellido </span><b> {this.state.me.last_name}</b></p>
                                    </DLElse>
                                </DLIf>
                            </div>
                            <div className="bio-row">
                                <p><span>Email </span><b> {this.state.me.email}</b></p>
                            </div>
                            <div className="bio-row">
                                <p><span>Firmas restantes </span><b> {this.state.quota}</b></p>
                            </div>
                            <DLIf if={this.state.editProfile}>
                                <div className={'float-right'}>
                                    <button className='btn btn-success btn-sm mr-1'
                                            onClick={() => this.updateUser()}>
                                        Guardar
                                    </button>
                                    <button  className='btn btn-danger btn-sm'
                                             onClick={() => {
                                                 this.setState({editProfile: false});
                                                 this.resetValues();
                                             }}>
                                         Cancelar
                                    </button>
                                </div>
                            </DLIf>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-6">
                    <div className="card">
                        <div className="card-header">
                            Crear documento
                        </div>
                        <div className="list-group">
                            { documents.map( doc => {
                                const badgeClass = this.getBadgeClass(doc.category);
                                return(
                                    <a className="list-group-item" href={`/documents/${doc.url}`}>
                                        {doc.name} <span className={`float-right badge ${badgeClass} text-uppercase`}>{doc.category}</span>
                                    </a>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
                <div  className="col-lg-6">
                    <div className="card">
                        <div className="card-header">
                            Historial reciente
                        </div>
                        <div className="list-group">
                            { purchaseHistory.map( doc => {
                                const url = doc.document ?  doc.document.template ? `documents/${doc.document.template.url}/${doc.document._id}/preview`: '' : '';
                                return(
                                    <div className="list-group-item">
                                        {doc.item.item.name}
                                        <DLIf if={url !== ''}>
                                            <a href={url}>
                                                <FontAwesomeIcon  className={'ml-1'} style={{cursor:'pointer'}} icon={'eye'}></FontAwesomeIcon>
                                            </a>
                                        </DLIf>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>


                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className={'float-right'}>
                                <button className='btn btn-info btn-sm mr-1'
                                        onClick={() => this.setState({editProfile:true})}>
                                    <FontAwesomeIcon  icon='pencil-alt'/> Editar
                                </button>
                                <button  className='btn btn-warning btn-sm' onClick={() => this.requestRestPassword()}>
                                    <FontAwesomeIcon  icon='key' /> Reestablecer contraseña
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    requestRestPassword() {
        DocuLegalApi.requestPasswordReset(this.state.me.email).then((r) =>{
            if(r.data.success){
                Swal.fire({
                    title: 'Envío existoso',
                    text: 'Recibirás un correo con las instrucciones para reestablecer tu contraseña.',
                    icon: 'success',
                })
            }

        }).catch((err) =>{

        })
    }
}
export default CFProfile;
