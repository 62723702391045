const id = 'rfcv';
let defaultProps = {
}
const extractProps = function (validator) {
    const props = validator.props || {};
    const keys = Object.keys(props);
    for (const key in keys) {
        defaultProps[keys[key]] = props[keys[key]];
    }
}
const extractValue = function (field) {
    return field.value || '';
}
const validateRfc = function (field, validator) {
    extractProps(validator)
    const value = extractValue(field).trim();
    const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    let isValid = re.test(String(value));
    return {
        isValid: isValid,
        message: isValid ? '' :`El campo ${field.field.description.toLowerCase()} debe de contener letras en mayuscula y numeros`
    }
}
module.exports = {
    id: id,
    validate: validateRfc
}
