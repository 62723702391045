/* Copyright (C) Startuplab MX. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Darien Miranda <dmiranda@nodeport.co> October 2020
*/

// Imports
import React from "react";
import Swal from "sweetalert2";

const DocuLegalApi = require('../../api/DocuLegalApi');


class RequestPasswordResetView extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
        }
    }
    onChange(c){
        this.setState({
            email:c.target.value
        })
    }
    render() {
        return (
            <div className="container">
                <div className="form-signin mb-5">
                    <h2 className="form-signin-heading">Recuperar contraseña</h2>
                    <div className="login-wrap">
                        <div>
                            <div className=''>
                                <label className='col-lg-12 control-label'>Tu correo electrónico</label>
                                <div className='col-lg-12'>
                                    <input className='form-control'
                                           type="text"
                                           value={this.state.email}
                                           onChange={(c) => this.onChange(c)}
                                           placeholder={'Escribe tu correo electrónico'}
                                    />
                                </div>
                            </div>

                            {/*<input type='email' className={'form-control'} placeholder={'Escribe tu correo electrónico'}/>*/}
                        </div>
                        <div className="col-lg-12 m-bot20">
                            ¿No quieres cambiar tu contraseña? <a href={'/login'}>Regresar</a>
                        </div>
                        <div className="col-lg-12">
                            <button className="btn btn-lg btn-danger btn-block" onClick={() => this.sendPasswordResetRequest()}>Recuperar contraseña</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    sendPasswordResetRequest() {
        DocuLegalApi.requestPasswordReset(this.state.email).then((r) =>{
            if(r.data.success){
                Swal.fire({
                    text: 'Recibirás un correo con las instrucciones para reestablecer tu contraseña.',
                    icon: 'success',
                    confirmButtonText:'Aceptar',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.history.push('/');
                    }
                });
            }else{
                Swal.fire({
                    text:r.data.error_es,
                    icon:'error'
                })
            }
        }).catch((err) =>{
            Swal.fire({
                text:err.response.data.error_es,
                icon:'error'
            })
        })
    }
}
export default RequestPasswordResetView;
