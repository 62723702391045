/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by América Mendoza <amendoza@nodeport.co> August 2020
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const SIGNATURES = [
    {id:'fiel', name:'Firma FIEL', description:'Firma utilizando tu FIEL (1 crédito)',icon:'file-signature'},
    {id:'digital', name:'Firma Digital', description:'Firma utilizando tu mouse o pantalla',icon:'tablet-alt'},
    {id:'autograph', name:'Firma Autógrafa', description:'Imprime tu documento para después firmarlo',icon:'signature'},
]

class DLSelectSignatureType extends React.Component {
    constructor(p){
        super(p);
        this.onSelectSignature     = this.onSelectSignature.bind(this);
        this.onClickClose          = this.onClickClose.bind(this);
    }

    onSelectSignature(item){
        this.props.onSelectSignature(item);
    }

    onClickClose(){
        this.props.onClickClose(false);
    }

    render(){
        if(this.props.show){
            return (
                <Modal show={this.props.show}
                       onHide={() => this.onClickClose()}
                       onExit={() => this.onClickClose()}
                       size="lg">
                  <Modal.Header closeButton>
                    <Modal.Title>¿Cómo quieres firmar tu documento?</Modal.Title>
                  </Modal.Header>
                  <Modal.Body >
                    <div className={"row"}>
                        {SIGNATURES.map( (item,i) => {
                            return(
                                <div className={"col-lg-4 col-xl-4"} key={SIGNATURES[i].id}>
                                    <div className={"card"} onClick={() => this.onSelectSignature(SIGNATURES[i].id)}>
                                        <div className={"card-body d-flex  flex-column align-items-center"}>
                                            <FontAwesomeIcon color="#357aaf" icon={SIGNATURES[i].icon} size='6x'/>
                                            <h3 className={'mt-4'} style={{fontSize: '1.55rem'}}>{SIGNATURES[i].name}</h3>
                                            <p>{SIGNATURES[i].description}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="danger" onClick={() => this.onClickClose()}>
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
            )
        }else{
            return null
        }
    }
}
export default DLSelectSignatureType;
DLSelectSignatureType.propTypes = {
    onSelectSignature         : PropTypes.func,
    onClickClose              : PropTypes.func,
    show                      : PropTypes.bool,
}
DLSelectSignatureType.defaultProps = {
    onSelectSignature         : () => {},
    onClickClose              : () => {},
    show                      : false
};

