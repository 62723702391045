/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Darien Miranda <dmiranda@nodeport.co> July 2020 &
* America Mendoza <amendoza@nodeport.co> August 2020
*/
import React from 'react';
import PropTypes from 'prop-types';

//import components
import DLErrorDisplay from "../DLErrorDisplay";
import DLIf from "../render/DLIf";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DLElse from "../render/DLElse";

class DLText extends React.Component{
    constructor(params) {
        super(params);
        this.state = {
            actualValue: ''
        }
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event){
        const fieldName = this.props.field.fieldName;
        const value     = event.target.value;
        this.props.onChange({[fieldName]:{
                field:this.props.field,
                value:value
            }})
        this.setState({
            [fieldName]:value,
            actualValue:value,
        });
    }

    render(){
        const name      = this.props.field.fieldName;
        const field     = this.props.field;
        const errors    = this.props.errors;
        const lastValue = this.props.lastValue ? this.props.lastValue:this.state.actualValue;
        const buttonAddOns  = field.buttonAddOnsText ? true : false;

        return(
            <div className={this.props.customClass.classWrapper}>
                <label className={this.props.customClass.classLabel} htmlFor={name}>{field.description} </label>
                <div className={this.props.customClass.classWrapperInput}>
                    <DLIf if={buttonAddOns}>
                        <div className="input-group-prepend">
                            {/*<div className="input-group-text"></div>*/}
                            <div className="input-group-text">
                                <DLIf if={field.buttonAddOnsText === '✉'}>
                                    <FontAwesomeIcon icon={['far','envelope']}>
                                    </FontAwesomeIcon>
                                    <DLElse>
                                        {field.buttonAddOnsText}
                                    </DLElse>
                                </DLIf>

                            </div>

                        </div>
                    </DLIf>
                    <input className={errors.length > 0 ? `${this.props.customClass.classInput} error` : this.props.customClass.classInput}
                           id={name}
                           name={name}
                           type="text"
                           value = {lastValue}
                           onChange={(e) => this.handleChange(e)}
                           placeholder={this.props.field.placeholder}
                    />
                    <DLErrorDisplay errors={errors}></DLErrorDisplay>
                </div>
            </div>
        )
    }
}
export default DLText;
DLText.propTypes = {
    field               : PropTypes.object,
    onChange            : PropTypes.func,
    errors              : PropTypes.array,
    customClass         : PropTypes.object,
    actualValue         : PropTypes.string
};


DLText.defaultProps = {
    field   :   {
                    handleName:'',
                    type:'text',
                    validator:'',
                    description:'',
                    fieldName:'',
                    defaultValue:'',
                    buttonAddOnsText:'',
                    placeholder:''
                },
    onChange : (change) => {},
    errors   : [],
    customClass:        {
        classWrapper        : 'form-group form-validation clearfix',
        classLabel          : 'col-lg-12 control-label',
        classWrapperInput   : 'col-lg-12',
        classInput          : 'form-control',
    },
    actualValue         : ''
};
