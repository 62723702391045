/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by America Mendoza <amendoza@nodeport.co>
*/
//imports
import React from "react";
import PropTypes from 'prop-types';
import { PDFViewer } from 'react-view-pdf';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

//import css
import '../style/jquery.steps.css'
import '../style/bootstrap-reset.css'
import DocuLegalApi from "../api/DocuLegalApi";

//import components

import DLHeader from "../components/DLHeader";
import DLFooter from "../components/DLFooter";
import DigitalSignature from "../components/DigitalSignature";


class DigitalSignRequestView extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            docId: '',
            document: {
                body        : '',
                description :'',
                forms       :[],
                name        :'',
            },
            compiledDocument:'',
            previewUrl:'',
            showModal:false,
            signature:false,
            authorized:false,
            showSelectSignature:false,
            showPricingTable:false,
            showModalRFC:false,
            selectedSignatoryType:null,
            token:'',
            myRef: React.createRef(),
        }
        this.handleModal                = this.handleModal.bind(this);
        this.signatureDocument          = this.signatureDocument.bind(this);
    }

    componentDidMount(){
        const docId = this.props.match.params.id;
        const previewUrl = `${process.env.REACT_APP_API_URL}/documents/${docId}/preview`
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const token = params.get('token');
        this.setState({
            token:token,
            previewUrl:previewUrl
        });
    }

    scrollSignature(){
        window.scrollTo(0, this.state.myRef.current.offsetTop);
    }

    signatureDocument(){
        const signature = localStorage.getItem("signatureImage64");
        const docId = this.props.match.params.id;
        const type = this.props.match.params.type;
        DocuLegalApi.sign(docId,this.state.token,signature.replace('data:image/png;base64,','')).then((res) =>{
           this.props.history.push(`/documents/${type}/${docId}/despues-firma`);
            // window.location.reload();
        }).catch((err) =>{
            const msg = err.response.data.error_es;
           Swal.fire({
               icon: "error",
               title: "Documento ya firmado",
               text: msg,
           });
        })
    }

    handleModal(showModal){
        this.setState({showModal})
    }

    renderContent(){
        return(
            <section className="wrapper">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body d-flex flex-row align-items-center">
                                <h5 className="mr-auto">Revisa tu documento</h5>
                                <button className="btn btn-primary ml-auto"
                                        onClick={()=>this.scrollSignature()}>
                                        Ir a firma <FontAwesomeIcon className="m-t-20 m-b-20" icon="arrow-circle-down" size='1x' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <PDFViewer url={this.state.previewUrl} />
                </div>
                <div ref={this.state.myRef} className="row mt-5 mb-4 d-flex justify-content-center">
                    <div className=" col-xs-8 col-sm-8 col-lg-6">
                        <DigitalSignature onClick={() => this.signatureDocument()}/>
                    </div>
                </div>
            </section>
        )
    }

    render(){
        return (
            <section id="container">
                {/*header start*/}
                <DLHeader />
                {/*header end*/}
                {/*main content start*/}
                <section id="main-content">
                    {/*signature content start*/}
                    {this.renderContent()}
                    {/*signature content end*/}
                </section>
                {/*main content end*/}
                {/*footer start*/}
                <DLFooter simple={true}/>
            </section>
        )
    }
}

DigitalSignRequestView.propTypes = {
    /** Document to be render. */
    doc: PropTypes.string,
};

DigitalSignRequestView.defaultProps = {
    doc: '',
};

export default DigitalSignRequestView;
