/*
 * Checks if the register form is not empty
 *
 * @params form Object
 *
 * @returns Boolean
*/
export function isRegisterComplete(user){
  if(!user.hasOwnProperty("name")){
    return false
  }
  if(!user.hasOwnProperty("last_name")){
    return false
  }
  if(!user.hasOwnProperty("email")){
    return false
  }
  if(!user.hasOwnProperty("password")){
    return false
  }
  return true
}
/*
 * Checks if the login form is not empty
 *
 * @params form Object
 *
 * @returns Boolean
*/
export function isLoginComplete(form) {
    if (!form.hasOwnProperty("email")) {
        return false;
    }
    if (!form.hasOwnProperty("password")) {
        return false;
    }
    return true;
}