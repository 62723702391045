/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Oscar Pena <opena@nodeport.co> July 2020
*/
import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import DocuLegalApi from '../api/DocuLegalApi';
import DLIf from '../components/render/DLIf';
import DLElse from "./render/DLElse";
import Session from "../api/Session";
import Logo from "../img/logo.png";

class DLHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            isLoggedIn:false,
            isLoaded:false,
            isAdmin:false
        }
    }

    componentDidMount() {
        const user = Session.getUser();
        try{
            const u = JSON.parse(user);
            this.setState({
                user:u.name
            })
        }catch(ex){

        }
        DocuLegalApi.getMe().then((r) =>{
            if(r.success){
                const name = `${r.user.name}`;
                const isAdmin = r.user.roles.includes('admin');
                this.setState({
                    isLoggedIn:true,
                    name:name,
                    isAdmin:isAdmin
                })
            }else{
                this.setState({
                    isLoggedIn:false,
                })
            }
        })
    }
    render(){
        return(
            <Navbar className="header white-bg" expand="lg">
                <Navbar.Brand href="/">
                    <img src={Logo} height={35} alt={'logo'}></img>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <div className="mr-auto"></div>
                    <Nav>
                        <DLIf if={this.state.isLoggedIn}>
                            <NavDropdown className={'nav-padding'} title = {this.state.name} id="user-dropdown" alignRight>
                                <DLIf if={this.state.isAdmin}>
                                    <NavDropdown.Item href={"/admin/usuarios"}>Ver usuarios</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                </DLIf>
                                <NavDropdown.Item href={"/perfil"}>Mi cuenta</NavDropdown.Item>
                                <NavDropdown.Item href={"/documents"}>Crear documento</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => Session.signOut()}>Cerrar sesión</NavDropdown.Item>
                            </NavDropdown>
                            <DLElse>
                                <Nav.Link className={'nav-padding'} href="/sign-in">Registrarse</Nav.Link>
                                <Nav.Link className={'nav-padding'} href="/login">Iniciar sesión</Nav.Link>
                            </DLElse>
                        </DLIf>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}
export default DLHeader;
