import React from 'react';
import PropTypes from 'prop-types';
//components
import ComponentResolver from '../components/ComponentResolver';
const {validate} = require('./validators/index');

class DLForm extends React.Component{
  constructor(p){
    super(p);
    this.state = {
      document: null,
      currentForm:{
        fields:[]
      },
      validations:{}
    }
    this.onChange = this.onChange.bind(this);
    this.isValidHelper  = this.isValidHelper.bind(this);
    this.errorsHelper   = this.errorsHelper.bind(this);
  }
  async onChange(change){
    const v = validate(change).then((r) =>{
      const key = Object.keys(r)[0];
      const validations = this.state.validations;
      validations[key] = {
        isValid:r[key].isValid,
        results:r[key].results
      }
      this.setState({
        validations: validations
      });
    })
    // const v2 = await validate(change);
    this.props.onChange(change);
  }
  isValidHelper(fieldName){
    if(this.state.validations.hasOwnProperty(fieldName)){
      return this.state.validations[fieldName].isValid;
    }else{
      return null; // returns null when it has not been validated
    }
  }
  errorsHelper(fieldName){
    if(this.state.validations.hasOwnProperty(fieldName)){
      return this.state.validations[fieldName].results.filter( (r) => r.isValid === false);
    }else{
      return []; // returns [] when it has not been validated
    }
  }
  formValue(handleName){
    let map = new Map(Object.entries(this.props.lastValues));
    return  map.get(handleName);
  }
  render(){
    const currentForm = this.props.form;
    return (currentForm.fields.map((field) => {
      return (
          <ComponentResolver
              key={field.fieldName}
              field={field}
              onChange={(change) => this.onChange(change)}
              isValid={this.isValidHelper(field.fieldName)}
              errors={this.errorsHelper(field.fieldName)}
              lastValue={this.formValue(field.handleName)}
              customClass={this.props.customClass}
          ></ComponentResolver>)
      })
    )
  }
  focusField(field){
    // alert('focus',field)
  }
  isFormValid(){
    const keys  = Object.keys(this.state.validations);
    let validations = [];
    keys.forEach((k) =>{
      validations.push(this.state.validations[k]);
    })
    // todo: check validator of all fields even if the user did not type in it
    const isValid = validations.length === 0 ? false : validations.map((v) => v.isValid).includes(false) ? false : true;
    return isValid;
  }
}
export default DLForm;
DLForm.propTypes = {
  onChange    : PropTypes.func,
  lastValues  : PropTypes.object,
  customClass : PropTypes.object,
  form        : PropTypes.object
};


DLForm.defaultProps = {
  onChange  : (change) => {},
  lastValues: {},
  customClass: {},
  form: {}
};
