/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by America Mendoza <amendoza@nodeport.co>
*/
//imports
import React from "react";
import PropTypes from 'prop-types';
import Swal from "sweetalert2";
import DocuLegalApi from "../api/DocuLegalApi";
import Cards from 'react-credit-cards';


//import css
import '../style/bootstrap-reset.css'
import 'react-credit-cards/es/styles-compiled.css'

//import components
import DLHeader from "../components/DLHeader";
import DLFooter from "../components/DLFooter";
import DLIf from "../components/render/DLIf";
import DLElse from "../components/render/DLElse";
import Spinner from "react-bootstrap/Spinner";


class PaymentView extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            cvc: '',
            year: '',
            month:'',
            email:'',
            phone:'',
            focus: '',
            name: '',
            number: '',
            planName: '',
            planPrice:'',
            isProcessing:false
        }
        this.getMe                       = this.getMe.bind(this);
        this.handleInputFocus            = this.handleInputFocus.bind(this);
        this.handleInputChange           = this.handleInputChange.bind(this);
        this.pay                         = this.pay.bind(this);
        this.loadConekta                 = this.loadConekta.bind(this);
        this.onSuccessCallback           = this.onSuccessCallback.bind(this);
        this.onErrorCallback             = this.onErrorCallback.bind(this);
    }

    componentDidMount(){
        const selectedPlan = this.props.location.state ? this.props.location.state.plan : null;
        this.afterSuccessfulPaymentUrl = this.props.location.state ? this.props.location.state.afterSuccessfulPaymentUrl : null;
        if(! selectedPlan){
            this.props.history.push({
                pathname:"/comprar-paquete",
            });
        }else{
            this.setState({
                planName:this.props.location.state.plan.name,
                planPrice:this.props.location.state.plan.price,
            })
        }
        this.getMe();
        this.loadConekta();
    }
    redirectToProfile(){
        this.props.history.push({
            pathname:"/perfil",
            state:{
                section:'payments'
            }
        });
    }
    redirectToDocument(){
        this.props.history.push({
            pathname:this.afterSuccessfulPaymentUrl
        });
    }
    loadConekta(){
        const script = document.createElement('script');
        script.src = 'https://cdn.conekta.io/js/latest/conekta.js';
        script.async = true;
        document.body.appendChild(script);
        const _this = this;
        script.onload = function() {
            window.Conekta.setPublicKey(process.env.REACT_APP_CONEKTA_PUBLIC_KEY);
            _this.Conekta = window.Conekta;
        };
    }
    getMe(){
        DocuLegalApi.getMe().then((u) =>{
            if(u.success){
                this.setState({
                    authorized:true,
                    email:u.user.email,
                    verified:u.user.isVerified
                });
            }else{
                this.setState({
                    authorized:false,
                    verified:false
                });
            }
        }).catch((err) =>{
            this.setState({
                authorized:false,
                verified:false
            });
        })
    }

    handleInputFocus(e){
        this.setState({ focus: e.target.name });
    }

    handleInputChange(e){
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }
    onSuccessCallback(res){
        const token = res.id;
        const customerInfo = {
            name:this.state.name,
            email:this.state.email,
            phone:`+52${this.state.phone}`
        }
        // make the charge through our API
        DocuLegalApi.makePayment(this.props.location.state.plan.item,token,customerInfo).then((r) =>{
            this.setState({
                isProcessing:false
            })
            Swal.fire(
                "Gracias por tu compra",
                `Gracias por tu compra, ahora puedes firmar mas documentos`,
                "success"
            ).then((result) => {
                // redirect
                if(this.afterSuccessfulPaymentUrl === null){
                    this.redirectToProfile();
                }else{
                    this.redirectToDocument();
                }
            });
        }).catch((err) =>{
            this.setState({
                isProcessing:false
            })
            if(err.response.data.error_es){
                Swal.fire(
                    "Revisa tus datos",
                    err.response.data.error_es,
                    "warning"
                );
            }else{
                Swal.fire(
                    "No pudimos procesar tu pedido",
                    `Hubo un problema con tu compra, intenta mas tarde`,
                    "error"
                );
            }

        })
    }
    onErrorCallback(err){
        this.setState({
            isProcessing:false
        })
        Swal.fire(
            "Revisa tus datos",
            err.message_to_purchaser,
            "warning"
        );
    }
    pay(){
        this.setState({
            isProcessing:true
        })
        const tokenParams = {
            card: {
                number: this.state.number,
                name: this.state.name,
                exp_year: this.state.year,
                exp_month: this.state.month,
                cvc: this.state.cvc
            }
        }
        window.Conekta.Token.create(tokenParams,this.onSuccessCallback,this.onErrorCallback)
    }

    render(){
        return (
            <section id="container">
                {/*header start*/}
                <DLHeader />
                {/*header end*/}
                {/*main content start*/}
                <section id="main-content">
                    <div className="wrapper">
                        <div className="container">
                            <div className={"row mt-5"}>
                                <div className={"col-lg-12 my-4"}>
                                    <h3 className={'mb-4'}>Comprar {this.state.planName} ({this.state.planPrice})</h3>
                                    <p> Ingresa los datos de tu tarjeta para continuar con la compra.  </p>
                                </div>

                                <div id="PaymentForm" className={"col-lg-12 my-4"}>
                                    <div className={'card'}>
                                        <div className={'card-body'}>
                                            <Cards
                                                cvc={this.state.cvc}
                                                expiry={`${this.state.month}${this.state.year}`}
                                                focused={this.state.focus}
                                                name={this.state.name}
                                                number={this.state.number}
                                                acceptedCards={['visa', 'mastercard']}
                                                placeholders={{name:'Nombre del propietario'}}
                                            />
                                            <div className={'form-group mt-4'}>
                                                <input
                                                    className={'form-control mb-2'}
                                                    type="tel"
                                                    name="number"
                                                    placeholder="Número de tarjeta"
                                                    onChange={this.handleInputChange}
                                                    onFocus={this.handleInputFocus}
                                                    maxLength={16}
                                                    minLength={16}
                                                    data-conekta="card[number]"
                                                />
                                                <input
                                                    className={'form-control mb-2'}
                                                    type="text"
                                                    name="name"
                                                    placeholder="Nombre del propietario"
                                                    onChange={this.handleInputChange}
                                                    onFocus={this.handleInputFocus}
                                                    data-conekta="card[name]"

                                                />
                                                <input
                                                    className={'form-control mb-2'}
                                                    type="text"
                                                    name="phone"
                                                    placeholder="Número de teléfono a 10 dígitos"
                                                    onChange={this.handleInputChange}
                                                    onFocus={this.handleInputFocus}
                                                    maxLength={10}
                                                    minLength={10}

                                                />
                                                <input
                                                    className={'form-control mb-2'}
                                                    type="text"
                                                    name="month"
                                                    placeholder="mes"
                                                    onChange={this.handleInputChange}
                                                    onFocus={this.handleInputFocus}
                                                    maxLength={2}
                                                    minLength={2}
                                                    data-conekta="card[exp_month]"
                                                />
                                                <input
                                                    className={'form-control mb-2'}
                                                    type="text"
                                                    name="year"
                                                    placeholder="año"
                                                    onChange={this.handleInputChange}
                                                    onFocus={this.handleInputFocus}
                                                    maxLength={2}
                                                    minLength={2}
                                                    data-conekta="card[exp_year]"
                                                />
                                                <input
                                                    className={'form-control mb-4'}
                                                    type="text"
                                                    name="cvc"
                                                    placeholder="CVC"
                                                    onChange={this.handleInputChange}
                                                    onFocus={this.handleInputFocus}
                                                    maxLength={3}
                                                    data-conekta="card[cvc]"
                                                />
                                                <DLIf if={this.state.isProcessing}>
                                                    <div className={'d-flex justify-content-center'}>
                                                        <Spinner  animation={'border'} variant={'primary'}></Spinner>
                                                    </div>
                                                    <DLElse>
                                                        <div className={'btn btn-primary btn-block'} onClick={() => this.pay()}>Pagar</div>
                                                    </DLElse>
                                                </DLIf>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                {/*main content end*/}
                {/*footer start*/}
                <DLFooter simple={true}/>
            </section>
        )
    }
}

PaymentView.propTypes = {
    /** Document to be render. */
    upgradeTo: PropTypes.string,
};

PaymentView.defaultProps = {
    upgradeTo: 'Deluxe',
};

export default PaymentView;
