const id = 'passwordv';
let defaultProps = {
}
const extractProps = function(validator){
    const props = validator.props || {};
    const keys = Object.keys(props);
    for (const key in keys){
        defaultProps[keys[key]] = props[keys[key]];
    }
}
const extractValue = function(field){
    return field.value || '';
}
const validatePassword= function (field,validator){
    extractProps(validator)
    const value = extractValue(field).trim();
    const re = /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{6,12})\S$/;
    let isValid  = re.test(String(value));
    return {
        isValid:isValid,
        message: isValid ? '' :`El campo ${field.field.description.toLowerCase()} debe contener ál menos un número y una letra mayúsucula`
    }
}
module.exports = {
    id:id,
    validate:validatePassword
}
