/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Oscar Pena <opena@nodeport.co> August 2020 &
*/

import React from 'react';
import PropTypes from 'prop-types';
//import checkboxes from './checkboxes';
import Checkbox from './Checkbox';
class Square extends React.Component {
  render() {
    return (
      <button
        className="square"
        onClick={() => this.props.onClick()}
      >
        {this.props.value}
      </button>
    );
  }
}

class DLWeekDays extends React.Component {
    constructor(params) {
    super(params);

    this.state = {
      checkedItems: new Map(),
      squares: Array(1).fill(null),
      checkboxes: [
          {
            name: 'monday',
            key: 'monday',
            label: 'Lunes',
          },
          {
            name: 'tuesday',
            key: 'tuesday',
            label: 'Martes',
          },
          {
            name: 'wednesday',
            key: 'wednesday',
            label: 'Miercoles',
          },
          {
            name: 'thursday',
            key: 'thursday',
            label: 'Jueves',
          },
          {
            name: 'friday',
            key: 'friday',
            label: 'Viernes',
          },
          {
            name: 'saturday',
            key: 'saturday',
            label: 'Sabado',
          },
          {
            name: 'sunday',
            key: 'sunday',
            label: 'Domingo',
          },
        ]

    }

    this.handleChange = this.handleChange.bind(this);
  }
  handleClick(i) {
    const fieldName = this.props.field.fieldName;
    const value = fmtDays(this.state.checkedItems);
    this.props.onChange({[fieldName]:{
      field:this.props.field,
      value:value
      }
    })
   }
   renderSquare(i){
     return (
       <Square
         value={'Aceptar'}
         onClick={() => this.handleClick(i)}
       />
     );
   }


  handleChange(e) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
  }


  render() {
    return (
      <div>
      <p>{this.props.field.description}</p>
      <>
        {
          this.state.checkboxes.map(item => (
            <label key={item.key}>
              <Checkbox name={item.name} checked={this.state.checkedItems.get(item.name)} onChange={this.handleChange} />
              {item.label}
            </label>
          ))
        }
      </>
      {this.renderSquare(0)}
        </div>
    );
  }
}

function fmtDays(values)
{
  const days=["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
  const days_es=["lunes", "martes", "miercoles", "jueves", "viernes", "sabado", "domingo"];
  var pick_days=[];
  var pick_days_String="";
  for(var j=0; j<days.length;j++){
    for (let [key, value] of values.entries()) {
      if(value && key===days[j])
        pick_days.push(days_es[j]);
    }
  }
  for(var i=0; i<pick_days.length;i++){
    if(i>0 && i===(pick_days.length-1))
        pick_days_String=`${pick_days_String} y `;
    else if(i>0)
        pick_days_String=`${pick_days_String}, `;
    pick_days_String=pick_days_String + pick_days[i].toString();
  }
  console.log(pick_days_String)
  return pick_days_String;
}

export default DLWeekDays;
DLWeekDays.propTypes = {
    field    : PropTypes.object,
    onChange : PropTypes.func,
    checkedItems:PropTypes.object
};

DLWeekDays.defaultProps = {
    field:{
        handleName:'',
        type:'text',
        validator:'',
        description:'',
        fieldName:'',
        defaultValue:''
    },
    onChange : (change) => {},
    checkedItems: '',
};
