/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Oscar Pena <opena@nodeport.co> August 2020
*/
import React from 'react';
import PropTypes from "prop-types";

const DLFooter = ({simple}) => {
  if(!simple){
    return(
        <footer className="main-footer">

          <div className="container">
            <div className="row">
              {/* Column 1 */}
              <div className="col-lg-5">
                <h3> CONTUFIRMA </h3>
                <ul>
                  <li>Edificio T, Piso 8, Facultad de Ingeniería de la UASLP</li>
                  <li>Dr. Manuel Nava #304</li>
                  <li>San Luis Potosí, SLP - México</li>
                  <li>Teléfono: 01 (444) 309-3750</li>
                  <li>Marcación extranjero: +52 (444) 309-3750</li>
                </ul>
              </div>
              {/* Column 2 */}
              <div className="col-lg-3">
                <h5>Mapa del sitio</h5>
                <ul>
                  <li>Sobre nosotros</li>
                  <a href="/">Documentos</a>
                  <li>Contacto</li>
                </ul>
              </div>
              {/* Column 3 */}
              <div className="col-lg-3">
                <h5> Soporte </h5>
                <ul>
                  <li>Centro de ayuda</li>
                  <li>Términos y Condiciones</li>
                  <li>Aviso de privacidad</li>
                </ul>
              </div>
            </div>
            <hr />
            <div className="text-center">
              2020 &copy; CONTUFIRMA by STARTUPLAB.
            </div>
          </div>
        </footer>
    )
  }
  else{
    return(
        <footer className="site-footer">
          <div className="container">
            <div className="text-center">
              2020 &copy; CONTUFIRMA by STARTUPLAB.
              {/* TODO: Remove this */}
              {/*<a href="#" className="go-top">*/}
              {/*  <FontAwesomeIcon className="m-t-20 m-b-20" color="#c2c2c2" icon="angle-up" size='1x' />*/}
              {/*</a>*/}
            </div>
          </div>
        </footer>
    )
  }


}
DLFooter.propTypes = {
  simple: PropTypes.bool,

}
DLFooter.defaultProps = {
  simple: false,

}
export default DLFooter
