/* Copyright (C) Startuplab Mx - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Israel Arellano <Isra_darrell@startuplab.mx> August 2020
*/
// Imports
import React from "react";

//Components
import SignInComponent from '../../components/auth/SignInComponent'
import Swal from 'sweetalert2'
import PropTypes from "prop-types";

//FormData
const SignInData = require('../../data/auth/sign-in.json')

class SignInView extends React.Component {
    onUserRegisteredSuccess(user) {
        const token = user.result.token;
        localStorage.setItem('NPAuthToken',token);
        let  onLoginSuccessUrl = localStorage.getItem('onLoginSuccessUrl');
        onLoginSuccessUrl = onLoginSuccessUrl ? onLoginSuccessUrl : '/';
        localStorage.setItem('onLoginSuccessUrl','');
        this.props.history.push({
            pathname:onLoginSuccessUrl
        });
    }

    onUserRegisteredFailed(res) {
        const data = res.response.data;
        const errCode = data.error_code ? data.error_code : '';
        const msgTaken = ""+
        "Ya existe una cuenta con este correo electrónico."+
        "<br></br>Si no recuerdas tu contraseña puedes recuperla aquí:";
        const msg = errCode === 'RESPONSE_ERROR_EMAIL_ALREADY_TAKEN' ? msgTaken : data.error_es ? data.error_es : 'No se ha podido completar el registro. Intenta más tarde';
        Swal.fire({
            icon: 'error',
            html: msg,
            confirmButtonText:  'Recuperar Contraseña' ,
            cancelButtonText: 'Volver a intentar',
            showConfirmButton: errCode === 'RESPONSE_ERROR_EMAIL_ALREADY_TAKEN',
            showCancelButton:true
        }).then((r) =>{
            if(r.isConfirmed){
                if(errCode === 'RESPONSE_ERROR_EMAIL_ALREADY_TAKEN'){
                    window.location.href = '/recuperar-contrasena';
                }
            }

        })
    }

    render(){
      return (
      	<div className="container">
        	<SignInComponent 
            loginForm={SignInData}
            title={"¡Registrate aquí!"}
            onUserRegisteredSuccess={ (user) => this.onUserRegisteredSuccess(user) }
            onUserRegisteredFailed={ (res) => this.onUserRegisteredFailed(res) }/>
        </div>
      )
    }
}
SignInView.propTypes = {
    redirectToOnSuccess : PropTypes.string,
}
SignInView.defaultProps = {
    redirectToOnSuccess : ''

};
export default SignInView;
