/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Oscar Pena <opena@nodeport.co> August 2020 update September 2020
*/
//imports
import React,{ Fragment } from 'react';
import PropTypes from 'prop-types';

//import css
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//import components
import DLErrorDisplay from "../DLErrorDisplay";

class DLMltipleInput extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            inputList: this.setData(),
        }
    }
    handleInputChange  (index,e) {
        const list = [... this.state.inputList];
          list[index].fullName = e.target.value;
          this.updateData(list)
    };
    updateData(list) {
        let names="";
        const fieldName = this.props.field.fieldName;
        list.map((values,i)=>{
            if(i > 0 && i < list.length)
                names=`${names}, `;
            names=`${names}${values.fullName}`;
        });
        this.props.onChange({[fieldName]:{
                field:this.props.field,
                value:names
            }
        })
        this.setState({inputList:list});
    }
    handleRemoveFields (index){
        const list = [... this.state.inputList];
        list.splice(index, 1);
        this.updateData(list)
    };

    handleAddFields () {
         const list = [... this.state.inputList];
         list.push({ fullName: ""});
         this.setState({inputList:list});
     };

    setData () {
        const list = [];

        if (this.props.lastValue)
        {
            this.props.lastValue.split(", ").map((inputField, index) => {
                list.push({fullName: inputField});
            });
        }
        else
            list.push({fullName: ""});

        return list;

    };


    render(){

        const name      = this.props.field.fieldName;
        const description     = this.props.field.description;
        const errors    = this.props.errors;
       return (
           <div className={this.props.customClass.classWrapper}>
               <label className={this.props.customClass.classLabel} htmlFor={name}>{description} </label>
               <div className="form-row">
                   {this.state.inputList.map((inputField, index) => (
                       <Fragment key={`${inputField}~${index}`}>
                           <div className={this.props.customClass.classWrapperInput}>
                               <input
                                   name={`${name}~${index}`}
                                   type="text"
                                   className="form-control"
                                   value={inputField.fullName}
                                   onChange={event => this.handleInputChange(index, event)}
                               />
                           </div>
                           <div>
                               {this.state.inputList.length !== 1 && <button
                                   className="btn btn-link"
                                   type="button"
                                   onClick={() => this.handleRemoveFields(index)}
                               >
                                   <FontAwesomeIcon className="m-t-20 m-b-20"
                                                    icon="minus"
                                                    size='1x' />
                               </button>}

                            </div>
                       </Fragment>
                   ))}
                   <button
                       className="btn btn-link"
                       type="button"
                       onClick={() => this.handleAddFields()}
                   >
                       <FontAwesomeIcon className="m-t-20 m-b-20"
                                        icon="plus"
                                        size='1x' />
                   </button>
               </div>
               <DLErrorDisplay errors={errors}></DLErrorDisplay>
            </div>
       )
    }
}
export default DLMltipleInput;
DLMltipleInput.propTypes = {
    field    : PropTypes.object,
    onChange : PropTypes.func
};


DLMltipleInput.defaultProps = {
    field:{
        handleName:'',
        type:'text',
        validator:'',
        description:'',
        fieldName:'',
        defaultValue:''
    },
    onChange : (change) => {},
    errors   : [],
    customClass:        {
        classWrapper        : 'form-group form-validation clearfix',
        classLabel          : 'col-lg-12 control-label',
        classWrapperInput   : 'col-lg-8',
        classInput          : 'form-control',
    }
};
