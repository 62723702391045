/* Copyright (C) Startuplab MX. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Darien Miranda <dmiranda@nodeport.co> November 2020
*/

import React from "react";
import PropTypes from 'prop-types';
import {Button,Modal} from "react-bootstrap";
import Carousel from 'nuka-carousel';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class DLCreateAccountComponent extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            open:true
        };
    }
    render() {
        return(
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Crea tu cuenta gratis y obtén los siguientes beneficios:
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{textAlign:"center"}}>
                        <Carousel
                            renderCenterLeftControls={({previousSlide}) => (
                                <div style={{backgroundColor: "#15151578",color: "white",padding: 4,cursor:"pointer"}}onClick={previousSlide}>
                                    <FontAwesomeIcon style={{fontSize:20}} className="m-t-20 m-b-20" icon={['fas', 'chevron-left']} size='lg' />
                                </div>
                            )}
                            renderCenterRightControls={({nextSlide}) => (
                                <div style={{backgroundColor: "#15151578",color: "white",padding: 4,cursor:"pointer"}}onClick={nextSlide}>
                                    <FontAwesomeIcon style={{fontSize:20}} className="m-t-20 m-b-20" icon={['fas', 'chevron-right']} size='lg' />
                                </div>
                            )}>
                            <div className={'carousel-content'}>
                                <FontAwesomeIcon style={{fontSize:100,marginBottom:20}} className="m-t-20 m-b-20" icon="file-alt" size='lg' />
                                <div><b>1 firma FIEL gratis</b></div>
                            </div>
                            <div className={'carousel-content'}>
                                <FontAwesomeIcon style={{fontSize:100,marginBottom:20}} className="m-t-20 m-b-20" icon="cloud" size='lg' />
                                <div><b>Almacena tus documentos generados en la nube de ConTuFirma</b></div>
                            </div>
                            <div className={'carousel-content'}>
                                <FontAwesomeIcon style={{fontSize:100,marginBottom:20}} className="m-t-20 m-b-20" icon="file-contract" size='lg' />
                                <div><b>Firma utilizando tu FIEL, Firma Digital o firma en papel.</b></div>
                            </div>
                        </Carousel>
                    </p>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'link'} onClick={this.props.onLogin}>Iniciar sesión</Button>
                    <Button variant={'danger'} onClick={this.props.onSignup}>Registrarse</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
DLCreateAccountComponent.propTypes = {
    onHide: PropTypes.func,
    onLogin: PropTypes.func,
    onSignup: PropTypes.func,
    show: PropTypes.bool
}
DLCreateAccountComponent.defaultProps = {
    onHide: () => {},
    onLogin: () => {},
    onSignup: () => {},
    show:true
};

export default DLCreateAccountComponent;
