/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by America Mendoza <amendoza@nodeport.co>
*/
//imports
import React from "react";
import PropTypes from 'prop-types';

//import css
import '../style/bootstrap-reset.css'

//import components
import DLHeader from "../components/DLHeader";
import DLFooter from "../components/DLFooter";
import DLUpgradePlan from "../components/DLUpgradePlan";

class UpgradePlanView extends React.Component {

    render(){
        return (
            <section id="container">
                {/*header start*/}
                <DLHeader />
                {/*header end*/}
                {/*main content start*/}
                <section id="main-content">
                    <div className="wrapper">
                        <DLUpgradePlan history={this.props.history}></DLUpgradePlan>
                    </div>
                </section>
                {/*main content end*/}
                {/*footer start*/}
                <DLFooter simple={true}/>
            </section>
        )
    }
}

UpgradePlanView.propTypes = {
    /** Document to be render. */
    upgradeTo: PropTypes.string,
};

UpgradePlanView.defaultProps = {
    upgradeTo: '',
};

export default UpgradePlanView;
