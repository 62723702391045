const id = 'idv';
let defaultProps = {
}
const extractProps = function(validator){
    const props = validator.props || {};
    const keys = Object.keys(props);
    for (const key in keys){
        defaultProps[keys[key]] = props[keys[key]];
    }
}
const extractValue = function(field){
    return field.value || '';
}
const validateIdv = function (field,validator){
    extractProps(validator)
    const val = ["INE", "Cartilla Militar", "Cédula Profesional", "Pasaporte"];
    let pos=0;
    const value = extractValue(field).trim();
    let re ='';
    let message ='';
    const array = value.split(" ");
    const  length= array.length;
    let lengStr=0;
    for(let i=0;i<val.length;i++){
        if(value.includes(val[i]))
            pos=i;
    }
    switch(pos)
    {
        case 0:
            re =/^([0-9]{13})$/;
            message="debe de contener 13 números";
            lengStr=13;
            break;
        case 1:
            re =/^([A-Z]{1}[0-9]{8})$/;
            message="debe de contener 1 letra y 8 números";
            break;
        case 2:
            re =/^([0-9]{7})$/;
            message="debe de contener 7 números";
            lengStr=7;
            break;
        case 3:
            re =/^([A-Z]{1}[0-9]{8})$/;
            message="debe de contener 1 letra y 8 números";
            lengStr=9;
            break;
        default:
            re =/^([0-9]{13})$/;
            message="debe de contener 13 números";
            lengStr=13;
            break;

    }
    let isValid  = re.test(String(array[length-1]));
    return {
        isValid:isValid,
        message: isValid ? '' :
        `El campo ${field.field.description.toLowerCase()} ${message}`
    }
}
module.exports = {
    id:id,
    validate:validateIdv
  }