module.exports = {
    signOut: () =>{
        localStorage.setItem('NPAuthToken','');
        localStorage.setItem('NPUser','');
        window.location.href = '/';
    },
    saveUser: (user) =>{
        localStorage.setItem('NPUser',JSON.stringify(user))
    },
    getUser: () =>{
        return localStorage.getItem('NPUser');
    }
}
