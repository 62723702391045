/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Oscar Pena <opena@nodeport.co>, July 2020 &
* America Mendoza <amendoza@nodeport.co> August 2020
*/

import React from 'react';
import PropTypes from 'prop-types';

//import component
import DLErrorDisplay from "../DLErrorDisplay";

class DLTextSelector extends React.Component{
    constructor(p) {
        super(p);
        this.state = {
          //values :this.props.field.defaultValue
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event){
      const fieldName = this.props.field.fieldName;
      const value     = event.target.value;
      this.props.onChange({[fieldName]:{
        field:this.props.field,
        value:value
      }})
      this.setState({
        [fieldName]:value
        }, () =>{
      });
    }
    // <div className="dl_text_container">
    //     {this.props.field.description}
    //     <select value={this.state.value} onChange={this.handleChange}>
    //     {
    //         this.props.field.defaultValue.map((n) =>{
    //             return(
    //                 <option value={n}>{n}</option>
    //             )
    //         })
    //     }
    //     </select>
    // </div>
    //Todo: Check if we can unify the DLSelector
    render(){
        const name = this.props.field.fieldName;
        const field = this.props.field;
        const errors = this.props.errors;
        const lastValue=this.props.lastValue;
        //Todo: check style, font size seems to be a little bigger then the other inputs.
        return(
            <div className="form-group form-validation clearfix">
                <label className="col-lg-12 control-label " htmlFor={name}>{field.description} </label>
                <div className="col-lg-12">
                    <select name={name}
                            className={errors.length > 0 ? 'custom-select error' : 'custom-select'}
                            value={lastValue}
                            onChange={(e) => this.handleChange(e)}>
                        {
                            field.defaultValue.map((n) =>{
                                return(
                                    <option key={n} value={n}>{n}</option>
                                )
                            })
                        }
                    </select>

                    <DLErrorDisplay errors={errors}></DLErrorDisplay>
                </div>
            </div>
        )
      }
    }
export default DLTextSelector;
DLTextSelector.propTypes = {
    field   : PropTypes.object,
    errors  : PropTypes.array
};

DLTextSelector.defaultProps = {
    field   :  {
                  handleName:'',
                  type:'text-selector',
                  validator:'',
                  description:'',
                  fieldName:'',
                  defaultValue:''
      },
    onChange : (change) => {},
    errors   : []
};
