/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by América Mendoza <amendoza@nodeport.co> August 2020
*/

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//components
import DLDocumentsGridRow from './DLDocumentsGridRow';

class DLDocumentsGrid extends React.Component {
    renderFailed() {
        return (
            <div className="text-center">
                <h3>Hubo un problema al mostrar los documentos</h3>
                <FontAwesomeIcon className="m-t-20 m-b-20" color="#c2c2c2" icon="exclamation-triangle" size='4x' />
                <h5>Inténtalo nuevamente</h5>
            </div>
        )
    }

    renderEmptyList() {
        return (
            <div className="text-center">
                <h3>No hay documentos disponibles</h3>
            </div>
        )
    }

    render() {
        const documents = this.props.documents;
        if (this.props.requestStatus === 'idle' || this.props.requestStatus === 'fetching') {
            return null
        } else if (this.props.requestStatus === 'success') {
            if (documents.length > 0) {
                return (
                    <div className='row'>
                        {documents.map((item, index) =>
                            (
                                <DLDocumentsGridRow key={index}
                                    document={item}
                                    onClickDocument={(document) => this.props.onClickDocument(document)}
                                />
                            )
                        )}
                    </div>
                )
            } else {
                return this.renderEmptyList()
            }
        } else {
            return this.renderFailed()
        }
    }
}

DLDocumentsGrid.propTypes = {
    /** List of documents */
    documents: PropTypes.array,

    /** Status of the loading data: idle, fetching, success and failed*/
    requestStatus: PropTypes.string, //idle,fetching,success,failed
}
DLDocumentsGrid.defaultProps = {
    documents: [],
    requestStatus: 'idle',
};

export default DLDocumentsGrid;

