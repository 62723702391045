/* Copyright (C) StartupLab MX - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Israel Arellano <Isra_darrell@startuplab.mx> September 2020
*/

import React from 'react';

class DLElse extends React.Component {
	render() {    
  		return this.props.children;
  	}
};

export default DLElse;