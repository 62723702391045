/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by America Mendoza <amendoza@nodeport.co> October 2020
*/

// Regular imports
import React from "react";
import DocuLegalApi from "../api/DocuLegalApi";
import Swal from "sweetalert2";

// Import styling
import '../style/bootstrap-reset.css'
import '../style/style.css'
import '../style/style-responsive.css'

import DLForm from "../components/DLForm";
import DLIf from "../components/render/DLIf";
import DLElse from "../components/render/DLElse";

const resetPwdForm = require('./../data/auth/reset-pwd.json');
class ResetPasswordView extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            me:{
                name: '',
                last_name: '',
                email: '',
            },
            fields:{},
            email:'',
            password:'',
            password_confim:'',
            loaded:false,
            isCodeValid:false
        }
        this.getMe                 = this.getMe.bind(this);
        this.onChange              = this.onChange.bind(this);
        /* Use ref to access DLForm state*/
        this.DLForm = React.createRef();
    }

    componentDidMount() {
        this.getMe();
        this.validateCode();
    }
    validateCode(){
        const code = this.props.match.params.code;
        DocuLegalApi.validatePasswordResetCode(code).then((r) =>{
            if(r.success){
                this.setState({
                    loaded:true,
                    isCodeValid:r.isValid
                })
            }else{
                this.setState({
                    loaded:true,
                    isCodeValid:false
                })
            }
        }).catch((err) =>{

        })
    }
    getMe(){
        DocuLegalApi.getMe().then((u) =>{
            if(u.success){
                this.setState({
                    me:u.user
                });
                this.getQuota();
            }
        }).catch((err) =>{
            console.log(err);
        })
    }
    resetPassword(){
        const code = this.props.match.params.code;

        if (this.isValidForm()) {
                DocuLegalApi.updatePassword(code,this.state.fields.password.value).then((u) =>{
                    if(u.success){
                        Swal.fire({
                            icon: 'success',
                            text: 'Contraseña actualizada, ya puedes ingresar utilizando tu nueva contraseña.',
                            confirmButtonText: 'Iniciar Sesión',
                            allowOutsideClick:false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = '/';
                            }
                        })
                    }
                }).catch((err) =>{
                    Swal.fire({
                        icon: 'error',
                        text: 'No pudimos actualizar tu contraseña. Intenta nuevamente más tarde',
                        confirmButtonText:'Volver a intentar'
                    })
                })

            } else {
                Swal.fire({
                    icon: 'error',
                    text: 'Revisa tus datos, te recordamos que debes de llenar todos los campos.',
                    confirmButtonText:'Volver a intentar'
                })
            }
    }
    isValidForm() {
        let isFormValid = this.DLForm.isFormValid();
        if (isFormValid && this.state.fields.password.value === this.state.fields.password_confirm.value) {
            return true
        }
        return false
    }
    onChange(change) {
        let key = Object.keys(change)[0];
        let fields = this.state.fields;
        fields[key] = change[key]
        this.setState({
            fields
        })
    }
    render() {
        return (
            <div className={'container'} style={{"marginTop":"80px"}}>
                <div className="row">
                    <div className="col-lg-6  ml-auto mr-auto">
                        <div className="card">
                            <div className="card-header">
                                Reestablecer contraseña
                            </div>
                            <DLIf if={this.state.loaded}>
                                <DLIf if={this.state.isCodeValid}>
                                    <div className="card-body">
                                        <DLForm ref={(DLForm) => { this.DLForm = DLForm }} form={resetPwdForm.forms[0]} onChange={(change) => this.onChange(change)}></DLForm>
                                        <button className="btn btn-primary float-right mt-3" onClick={() => this.resetPassword()}>Reestablecer contraseña</button>
                                    </div>
                                    <DLElse>
                                        El código para reestablecer la contraseña no es válido.
                                    </DLElse>
                                </DLIf>

                            </DLIf>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ResetPasswordView;
