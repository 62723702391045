import len from './len';
import email from './email';
import required from './required';
import money from './money';
import hourv from './hourv';
import textv from './textv';
import numberv from './numberv';
import curpv from './curpv';
import rfcv from './rfcv';
import textNumbers from './textNumbers';
import passwordv from './passwordv';
import idv from './idv';
import namev from './namev';
const VALIDATORS = [len,email,required, money, hourv, textv, numberv, curpv, rfcv, textNumbers, passwordv, idv, namev];
export const validate = async function(fields){
    const results = [];
    const fieldsKeys = Object.keys(fields);
    for(const fK in fieldsKeys){
        // Loop all the fields
        const currentField = fields[fieldsKeys[fK]];
        const fieldValidatorsResults = [];
        // Find the right validators for each field
        for (const v in currentField.field.validators){
            // console.log('currentField',currentField)
            const currentValidator = currentField.field.validators[v];
            const validator = VALIDATORS.find((v) =>
                v.id === currentValidator.validator);
            // console.log(validator)
            if(! validator){
                // If the validator does not exists, throw error
                return Promise.reject(`Validator ${currentField.field[v].validator} at field ${currentField.field.fieldName} does not exists`);
            }else{
                const res = validator.validate(currentField,currentValidator);
                // Try to validate
                fieldValidatorsResults.push(res);
            }
        }
        results[fieldsKeys[fK]] = {
            field:currentField,
            results:fieldValidatorsResults,
            isValid: fieldValidatorsResults.map(v => v.isValid).filter( v=> !v ).length === 0
        }
    }
    return Promise.resolve(results);
}
