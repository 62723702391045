/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by América Mendoza <amendoza@nodeport.co> August 2020
 & Oscar Pena <opena@nodeport.co> September 2020
*/

import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar, Step } from "react-step-progress-bar";
//css
import "react-step-progress-bar/styles.css";

class DLStepper extends React.Component {
  constructor(p){
    super(p);
    this.state={
      width:0
    }
    this.onClick          = this.onClick.bind(this);
    this.getPercentage    = this.getPercentage.bind(this);

  }

  onClick(index){
    this.props.onClick(index);
  }

  getPercentage(currentStep,steps){
    const totalSteps = steps.length > 0 ? steps.length -1 : 0;
    return 100/totalSteps * currentStep;
  }
  render(){
    const currentStep = this.props.currentStep;
    const steps = this.props.steps;
    return (
        <div className={"dl-stepper-wrapper"}>
          <ProgressBar
              percent={this.getPercentage(currentStep,steps)}
              filledBackground="linear-gradient(to right, #419cd2 , #1e293d)"
          >
            {
              steps.map((step,i) => {
                return(
                    <Step transition="scale" key={i}>
                      {({ accomplished, index }) => (
                          <div key={index} className={accomplished ? "dl-step accomplished" : "dl-step"} onClick={() => this.onClick(index)}>{index+1}</div>
                      )}
                    </Step>
                )
              })
            }
          </ProgressBar>
        </div>

    )
  }
}
export default DLStepper;
DLStepper.propTypes = {
  steps           : PropTypes.array,
  currentStep     : PropTypes.number,
  onClick         : PropTypes.func,

}
DLStepper.defaultProps = {
  steps           : ['form1','form2','form3','form4'],
  currentStep     : 0,
  onClick         : () => {}
};
