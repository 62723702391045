/* Copyright (C) Startuplab MX. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Juan Hernandez <juan@startuplab.mx> September 2020
*/

// Imports
import React from "react";
import Swal from "sweetalert2";

//Components
import LoginComponent from '../../components/auth/LoginComponent'

//FormData
const LoginData = require('../../data/auth/login.json')

class LoginView extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
        }
        this.onLoginSuccess = this.onLoginSuccess.bind(this);
        this.loginFailed = this.loginFailed.bind(this);
    }
    onLoginSuccess(res) {
        const token = res.result.token;
        localStorage.setItem('NPAuthToken',token);
        let  onLoginSuccessUrl = localStorage.getItem('onLoginSuccessUrl');
        onLoginSuccessUrl = onLoginSuccessUrl ? onLoginSuccessUrl : '/';
        localStorage.setItem('onLoginSuccessUrl','');
        this.props.history.push({
            pathname:onLoginSuccessUrl
        });
    }
    loginFailed(res) {
        const message =(res.response.data.error_es)?res.response.data.error_es:res.response.data.result.reason_es;
        // the parameter res has the login response fail
        // Do something
        /*        console.log(res.response);
        console.log(res.response.status);
        console.log(res.response.data.error_es);*/
        //console.log("shdiasdufasjdfasdfdf");
        //console.log(res.response);
        //console.log(res.response.data.result.reason_es);
        Swal.fire({
            icon: "error",
            text: "La contraseña ingresada es incorrecta.",
            confirmButtonText:'Volver a intentar'
        });
    }

    render() {
        return (
            <div className="container">
                <LoginComponent
                    loginForm={LoginData}
                    onLoginSuccess={(res) => this.onLoginSuccess(res)}
                    onLoginFailed={this.loginFailed}
                    title={"¡Bienvenido!"} />
            </div>
        )
    }
}
export default LoginView;
