const id = 'textNumbers';
let defaultProps = {
}
const extractProps = function(validator){
    const props = validator.props || {};
    const keys = Object.keys(props);
    for (const key in keys){
        defaultProps[keys[key]] = props[keys[key]];
    }
}
const extractValue = function(field){
    return field.value || '';
}
const validateTextNumbers = function (field,validator){
    extractProps(validator)
    const value = extractValue(field).trim();
    const re =/^([A-Z0-9]+)$/;
    let isValid  = re.test(String(value));
    return {
        isValid:isValid,
        message: isValid ? '' :
        `El campo ${field.field.description.toLowerCase()}
          debe de contener letras en mayuscula y numeros`
    }
}
module.exports = {
    id:id,
    validate:validateTextNumbers
  }
