import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

class DLViewer extends React.Component{

    render(){
        return(
            <section className="card">
                <div id="CF-editor" className="card-body" style={{height:600,overflow:'scroll'}}>
                    {
                        ReactHtmlParser(
                            this.props.document,{
                                transform: (node) => {
                                    const attribs = node.attribs;
                                    if(attribs){
                                        const  _class = attribs.class;
                                        if(_class){

                                            if( _class.includes('field')){
                                                const data = node.children.length > 0 ? node.children[0].data : '';
                                                if(node.children.length > 0){
                                                    if(node.children[0].attribs){
                                                        if(node.children[0].attribs.class === 'placeholder'){
                                                            const placeholderText = node.children[0].children.length > 0 ? node.children[0].children[0].data : '';
                                                            return(
                                                                <b
                                                                    // href={node.attribs.href}
                                                                    className={_class}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.props.onFieldClicked(_class)
                                                                    }}
                                                                >
                                                                <span className={'placeholder'}>
                                                                    {placeholderText}
                                                                </span>
                                                                </b>
                                                            )
                                                        }
                                                    }
                                                }
                                                return(
                                                        <b
                                                            // href={node.attribs.href}
                                                            className={_class}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.props.onFieldClicked(_class)
                                                            }}
                                                        >
                                                            {data}
                                                        </b>
                                                )
                                            }
                                        }
                                    }else{

                                    }

                                }
                            }
                        )
                    }
                </div>
            </section>

        )
    }

}
export default DLViewer;
DLViewer.propTypes = {
    document: PropTypes.string,
    onFieldClicked: PropTypes.func
};
DLViewer.defaultProps = {
    document : '',
    onFieldClicked : (field) => {}
};
