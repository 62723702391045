/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Darien Miranda <dmiranda@nodeport.co> &
* Oscar Pena <opena@nodeport.co> August 2020 &
* America Mendoza <amendoza@nodeport.co>
*/
//imports
import React from "react";
import PropTypes from 'prop-types';

//import css
import '../style/jquery.steps.css'
import '../style/bootstrap-reset.css'
import Fade from 'react-reveal/Fade';

//import components
import DocumentUploader from "../components/DocumentUploader";
import DLForm from "../components/DLForm";
import DLViewer from "../components/viewer/DLViewer";
import DLStepper from "../components/DLStepper";
import DLHeader from "../components/DLHeader";
import DLFooter from "../components/DLFooter";
import DocumentUtils from "../tools/document-utils";
import Swal from "sweetalert2";
import {Button} from "react-bootstrap";
import DLIf from "../components/render/DLIf";
import DLElse from "../components/render/DLElse";
import {PDFViewer} from "react-view-pdf";

//import const
const Handlebars = require("handlebars");
const DocuLegalApi = require("../api/DocuLegalApi");

class DocumentEditorView extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            template:{

            },
            document: {
                body        : '',
                description :'',
                forms       :[],
                name        :'',
            },
            currentForm: {
                name    :'',
                fields  :[]
            },
            fields:{},
            compiledDocument:'',
            step:0,
            steps:[],
            currentStep:0,
            previewUrl:'',
            lastValues:[], //keep value of every field form
            mirrorAnimation:false, //move animations
            previewMode:false
        }
        this.onPreviewCreated       = this.onPreviewCreated.bind(this);
        this.handleNavigation       = this.handleNavigation.bind(this);
        this.onFieldClicked         = this.onFieldClicked.bind(this);
    }

    validateMe(){
        DocuLegalApi.getMe().then((u) =>{
            if(!u.success){
                window.location.href = "/documents";
            }
        }).catch((err) =>{
            window.location.href = "/documents";
        })
    }

    componentDidMount(){
        this.validateMe();
        const documentType = this.props.match.params.type;
        DocuLegalApi.getTemplateByUrl(documentType).then((docTemplate) =>{
            this.setState({
                template:docTemplate.template
            })
            const data = docTemplate.template;
            const template = Handlebars.compile(data.doc.body);
            const placeholders = DocumentUtils.getPlaceholders(data);
            const compiledDocument = template(placeholders);

            this.setState({compiledDocument});
            //get document
            this.setState({document: data.doc});
            //get first form
            this.setState({currentForm: data.doc.forms[0]});
            //get steps (forms)
            this.getSteps(data.doc);
            // fill descriptions
            this.fillDescriptions();
        })

    }


    getSteps(data){
        if(data.forms){
            const steps = data.forms.map( form => form.title);
            this.setState({steps});
        }
    }

    handleNavigation(index) {
        if (index => 0 && index < this.state.steps.length){
            if(this.state.currentStep>index) //switch between left and right animation
                this.setState({mirrorAnimation: true});
            else
                this.setState({mirrorAnimation: false});
            this.setState({currentStep: index});
            const currentForm2 = this.state.document.forms[index];
            this.setState({currentForm: currentForm2});
            window.scrollTo({top:0,behavior:'smooth'})
        }
    }

    renderPreviousBtn() {
        const currentStep = this.state.currentStep;
        if (currentStep > 0) {
            return (
                <button className="btn btn-primary mr-2"
                        onClick={() => this.handleNavigation(currentStep - 1)}>Anterior</button>
            )
        }
    }

    onPreviewCreated(res){
        const type = this.props.match.params.type;
        if(res.data.success){
            this.props.history.push(`/documents/${type}/${res.data.document._id}/preview`);
        }
    }
    onSavedFailed(err){
        if(err.response.data.error === 'Not enough credits'){
            Swal.fire({
                title: 'Créditos insuficientes',
                text: 'Para firmar con la FIEL debes de tener créditos en tu cuenta.',
                icon: 'error',
                confirmButtonText:'Comprar créditos',
                showCancelButton:true,
                cancelButtonText:'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.props.history.push('/comprar-paquete');
                }
            });
        }
    }

    renderNextBtn(){
        const currentStep = this.state.currentStep;
        const steps = this.state.steps.length;
        if(currentStep === steps-1){
            return(
                <DocumentUploader template={this.state.template}
                                  document={this.state.compiledDocument}
                                  onPreviewCreated={(res) =>this.onPreviewCreated(res)}
                                  onSavedFailed={(err) => this.onSavedFailed(err)}>
                </DocumentUploader>
            )
        }else if(currentStep < steps){
            return (
                <button className="btn btn-primary ml-auto"
                        onClick={() => this.handleNavigation(currentStep +1)}>
                        Siguiente
                </button>
            )
        }
    }
    applyEffects(key){
        // apply effects
        const editor = document.getElementById("CF-editor");
        const fields = document.getElementsByClassName('field');

        for(let f = 0; f < fields.length; f ++){
            fields[f].classList.remove('current_field')
        }
        const current_fields = document.getElementsByClassName(key);
        for(let cf = 0; cf < current_fields.length; cf ++ ){
            current_fields[cf].classList.add('current_field');
        }
        if(document.getElementsByClassName(key).length > 0) {
            const offsetTop = document.getElementsByClassName(key)[0].offsetTop - 20;
            editor.scroll({top: offsetTop, behavior: 'smooth'})
        }

    }
    onFieldClicked(field){
        field = field.replace('field ','');
        const forms = this.state.document.forms;
        for (let f = 0; f < forms.length ; f ++){
            for(let _f = 0; _f < forms[f].fields.length; _f ++){
                if(field === forms[f].fields[_f].fieldName){
                    this.setState({
                        currentStep:f
                    })
                    this.DLFormRef.focusField(field);
                }
            }
        }
    }
    fillDescriptions(){
        // const fields = document.getElementsByClassName('field')
        // for(let f = 0; f < fields.length; f++){
        //     const field = fields[f];
        //     field.innerHTML = '<span class="description">description</span>';
        // }
    }
    onChange(change){
        let key = Object.keys(change)[0];
        let fields = this.state.fields;
        fields[key] = change[key]
        this.setState({
            fields
        })
        const template = Handlebars.compile(this.state.document.body);
        const keys = Object.keys(fields);
        let variables = {};
        keys.map( (k) => {
            variables[fields[k].field.handleName] =   fields[k].value;
        })
        this.setState({lastValues: variables});//assigns the last value of every field form
        const placeholders = DocumentUtils.getPlaceholders(this.state.template);
        const compiledDocument = template(Object.assign(variables,placeholders));
        this.setState({compiledDocument}, () =>{
            this.applyEffects(key);
        });
    }

    renderContent(){
        return(
            <section className="wrapper">
                {/* page start*/}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body" style={{display: 'flex',justifyContent: 'space-between'}}>
                                <h5 className={'m-0'}>{this.state.document.name}</h5>
                                <Button variant={'warning'} onClick={() => this.onPreviewClicked()}>{!this.state.previewMode ? 'Vista previa' : 'Editar'}</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <section className="card">
                            <header className="card-header">
                                <b>{this.state.currentForm.title}</b>
                            </header>
                            <div className="card-body">
                                <DLStepper steps={this.state.steps}
                                           currentStep={this.state.currentStep}
                                           onClick={(index) => this.handleNavigation(index)}/>

                                <form>
                                    <Fade key={this.state.currentStep} right mirror={this.state.mirrorAnimation} entrance appear>
                                        <div>
                                            <section>
                                                <DLForm
                                                        ref={(ref) => this.DLFormRef = ref}
                                                        onChange={(change) =>this.onChange(change)}
                                                        form={this.state.currentForm}
                                                        lastValues={this.state.lastValues}>
                                                </DLForm>
                                            </section>
                                        </div>
                                    </Fade>
                                </form>
                                <div className="card-body d-flex flex-row">
                                    {this.renderPreviousBtn()}
                                    {this.renderNextBtn()}
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="col-lg-8">
                        <DLIf if={this.state.previewMode === false}>
                            <DLViewer document={this.state.compiledDocument}
                                      fields={this.state.fields}
                                      onFieldClicked={(field) => this.onFieldClicked(field)}
                            >
                            </DLViewer>
                            <DLElse>
                                <PDFViewer url={this.state.previewUrl} />
                            </DLElse>
                        </DLIf>

                    </div>
                </div>

                {/* page end*/}
            </section>
        )
    }
    render(){
        return (
            <section id="container">
                {/*header start*/}
                <DLHeader />
                {/*header end*/}
                {/*main content start*/}
                <section id="main-content">
                    {this.renderContent()}
                </section>
                {/*main content end*/}
                {/*footer start*/}
                <DLFooter />
            </section>
        )
    }

    onPreviewClicked() {
        this.setState({
            previewMode:!this.state.previewMode
        })
        DocuLegalApi.createPreview(this.state.compiledDocument).then((r) =>{
          this.setState({
              previewUrl: r.link
          })
        })

    }
}

DocumentEditorView.propTypes = {
    /** Document to be render. */
    doc: PropTypes.string,
};

DocumentEditorView.defaultProps = {
    doc: '',
};

export default DocumentEditorView;
