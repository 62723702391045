/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by América Mendoza <amendoza@nodeport.co> August 2020
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import DLForm from "./DLForm";
import * as Swal from "sweetalert2";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DLIf from "./render/DLIf";

const FORM = require('../data/signatureForms/signature-form.json');
class DLModalSendSignatureRequest extends React.Component {
  constructor(p){
    super(p);
    this.state = {
      formData:{},
      forms:[0,1]
    }

    this.onClick         = this.onClick.bind(this);
    this.handleClose     = this.handleClose.bind(this);
    this.onChange        = this.onChange.bind(this);
    this.generateForm    = this.generateForm.bind(this);
  }

  onClick(index){
    this.props.onClick(index);
  }

  handleClose(){
    //reset forms values
    this.resetValues();
    //close modal from parent
    this.props.onClickClose(false);
  }
  onRequestSignatories(){
    // this.handleClose();
    const data = Object.keys(this.state.formData).map((k) => {return{[k]:this.state.formData[k]}})
    // const signatories = this.state.forms.map((form,index) =>{
    let signatories = [];
    let names = [];
    let tax_ids = [];
    let emails = [];
    let positions = [];
    let companies = [];
    let witnesses = [];
    for (const d in data){
      const fieldName = Object.keys(data[d])[0];
      if(fieldName.includes('name')){
        names.push(data[d])
      }
      else if(fieldName.includes('RFC')){
        tax_ids.push(data[d]);
      }
      else if(fieldName.includes('email')){
        emails.push(data[d]);
      }else if(fieldName.includes('position')){
        positions.push(data[d]);
      }else if(fieldName.includes('company')){
        companies.push(data[d]);
      }else if(fieldName.includes('is_witness')){
        witnesses.push(data[d]);
      }
    }
    const nRows = Math.max(names.length,tax_ids.length,emails.length,positions.length,companies.length,witnesses.length);

    for(let x = 0; x < nRows; x++){
      const _name      = names.find((n) => n.hasOwnProperty(`name_${x}`));
      const _tax_id    = tax_ids.find((t) => t.hasOwnProperty(`RFC_${x}`));
      const _email     = emails.find((e) => e.hasOwnProperty(`email_${x}`));
      const _position  = positions.find((p) => p.hasOwnProperty(`position_${x}`));
      const _company   = companies.find((c) => c.hasOwnProperty(`company_${x}`));
      const _isWitness = witnesses.find((w) => w.hasOwnProperty(`is_witness_${x}`));

      signatories.push({
        name: _name ? _name[`name_${x}`] : '',
        tax_id: _tax_id ? _tax_id[`RFC_${x}`] : '',
        email: _email ? _email[`email_${x}`] : '',
        position: _position ? _position[`position_${x}`] : '',
        company: _company ? _company[`company_${x}`] : '',
        isWitness: _isWitness ? _isWitness[`is_witness_${x}`] : false,
      })
    }

    const isFormValid = this.isFormValid(signatories);
    const rowsEmpty = this.isSomeValueEmpty(signatories);
    // check number of signatures
    const minSignatoriesReached = signatories.filter((r) => !r.isWitness).length >= 2;
    if(rowsEmpty){
      return Swal.fire({
        icon:'error',
        title:'Faltan datos',
        html: '1Para poder enviar el documento a firmar, debes de llenar todos los datos que se piden.',
        confirmButtonText: 'Actualizar Datos'
      });
    }
    if(!minSignatoriesReached){
      return Swal.fire({
        icon:'error',
        title:'Firmantes insuficientes',
        html: 'Debes de ingresar al menos dos firmantes excluyendo a los testigos.',
        confirmButtonText: 'Actualizar Datos'
      });
    }
    if(!isFormValid){
      return Swal.fire({
        icon:'error',
        title:'Faltan datos',
        html: '2Para poder enviar el documento a firmar, debes de llenar todos los datos que se piden.',
        confirmButtonText: 'Actualizar Datos'
      });
    }
    if(isFormValid && minSignatoriesReached){
      this.props.onRequestSignatories(signatories);
      this.handleClose();
    }
  }
  isSomeValueEmpty(rows){
    const isEmpty = function(v){
      v = v ? v.trim() : '';
      return !!! v;
    }
    const rowsEmpty = rows.map((d) => {
      if (this.props.signatureType === 'fiel') {
        return isEmpty(d.tax_id) || isEmpty(d.email) || isEmpty(d.name);
      }else if(this.props.signatureType === 'digital'){
        return isEmpty(d.email) || isEmpty(d.name);
      }else{
        return isEmpty(d.name);
      }
    });
    return rows.length === 0 ? true : rowsEmpty.every((r) => r === true);
  }
  isFormValid(rows){
    const validateRfc = function (value) {
      value = value ? value.trim() : '';
      const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
      return re.test(String(value));
    }
    const validateEmail = function (value){
      value = value ? value.trim() : '';
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(value).toLowerCase());
    }
    const validateName = function(value){
      value = value ? value.trim() : '';
      return value.length > 0;
    }
    const rowsValid = rows.map((d) => {
      if (this.props.signatureType === 'fiel') {
        return validateRfc(d.tax_id) && validateEmail(d.email) && validateName(d.name);
      }else if(this.props.signatureType === 'digital'){
        return validateEmail(d.email) && validateName(d.name);
      }else{
        return validateName(d.name);
      }
    });
    return rows.length === 0 ? false : rowsValid.every((r) => r === true);
  }
  resetValues(){
    this.setState({
      forms:[0,1],
      formData:{}
    });
  }

  onChange(event){
    const key = Object.keys(event)[0];
    this.setState(prevState => {
      let formData = Object.assign({}, prevState.formData);
      formData[`${event[key].field.fieldName}`] =  event[key].value;
      return {formData};
    })
  }
  generateForm(index){
    // Ugly way to copy data. We tried [...] and slice() but does not work.
    // Please let it be.
    // Don't judge us.
    // For real...
    let fields = JSON.parse(JSON.stringify(FORM.forms[0].fields));
    fields[0].fieldName = `name_${index}`;
    fields[1].fieldName = `email_${index}`;

    fields[3].fieldName = `position_${index}`;
    fields[4].fieldName = `company_${index}`;
    fields[5].fieldName = `is_witness_${index}`;
    if (this.props.signatureType === 'fiel') {
      fields[2].fieldName = `RFC_${index}`;
    } else if(this.props.signatureType === 'digital') {
      // remove #2
      fields.splice(2,1);
    }else{
      fields.splice(1,2);
    }
    return {
      name:'Datos del firmante',
      fields:fields
    }
  }
  addAnother(){
    if(this.state.forms.length < 10){
      const forms = this.state.forms;
      forms.push(0);
      this.setState({
        forms: forms
      })
    }

  }
  getClassWrapperValue(){
    // this.props.signatureType
    switch('') {
      case 'fiel':
        return 'col-lg-4'
        break;
      case 'digital':
        return 'col-md-10 col-lg-6'
        break;
      default:
        return 'col-sm-12'
    }
  }
  render(){
    const classWrapper = this.getClassWrapperValue();
    const classObject = {
      classWrapper: `${classWrapper} form-validation clearfix`,
      classLabel:'sr-only',
      classWrapperInput:'input-group mb-2',
      classInput: 'form-control',
    }
    const btnLabel = this.props.signatureType === 'autograph' ? 'Generar documento' : 'Solicitar firmas';
    if(this.props.show){
      return (
          <div className={"dl-select-signature"}>
            <div className="wrapper" style={{height:'100%'}}>
              <div className={"container d-flex flex-column"} style={{minHeight:'100%'}}>

                <div className={"row mt-5 mb-4"}>
                  <div className={"col-lg-12"}>
                    <h3>Solicitar firmas</h3>
                  </div>
                </div>

                <div className={"row flex-grow-1"}>
                  <div className={"col-lg-8 mr-auto ml-auto"}>
                    {
                      this.state.forms.map((f,index) =>{
                        return(
                            <div className={"form-row mb-3"} key={f}>
                              <p className={'mb-1'} style={{fontSize:14}}>Firmante {index+1}</p>
                              <DLForm key={f} onChange={(change) =>this.onChange(change)}
                                      form={this.generateForm(index)}
                                      customClass={classObject}
                              >
                              </DLForm>
                            </div>
                        )
                      })
                    }
                    <div className={"mt-2"}>
                      <DLIf if={this.state.forms.length < 10}>
                        <a className={'d-flex justify-content-start '} style={{cursor:"pointer"}} onClick={() =>{this.addAnother()}} >Agregar otro firmante</a>
                      </DLIf>
                    </div>
                  </div>
                </div>

                <div className={"row mt-2 mb-2"}>
                  <div className={"col-lg-12 d-flex justify-content-end"}>
                    {/*<button className={'btn btn-link'} onClick={() => this.handleClose()}>Cancelar</button>*/}
                    <Button className={"mr-1"} variant="danger" onClick={() => this.handleClose()}>
                      Cancelar
                    </Button>
                    <Button variant="primary" onClick={() => this.onRequestSignatories()}>
                      {btnLabel}
                    </Button>
                  </div>
                </div>

              </div>
            </div>
          </div>
      )
    }else{
      return null
    }
  }
}
export default DLModalSendSignatureRequest;
DLModalSendSignatureRequest.propTypes = {
  show                 : PropTypes.bool,
  onClickClose         : PropTypes.func,
  signatureType        : PropTypes.string
}
DLModalSendSignatureRequest.defaultProps = {
  show                 : false,
  onClickClose         : () => {},
  signatureType        : 'a'
};
