/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Darien Miranda <dmiranda@nodeport.co> & Oscar Pena <opena@nodeport.co> August 2020
*/
import React from "react";
import DocuLegalApi from '../api/DocuLegalApi';
import PropTypes from "prop-types";
import CreditsPopup from "./store/CreditsPopup";
import DLCreateAccountComponent from "./auth/DLCreateAccountComponent";

class DocumentUploader extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            showCreateAccountPopup:false
        }
        this.goToLogin = this.goToLogin.bind(this);
        this.goToSignIn  = this.goToSignIn.bind(this);
    }
    savePreview(){
        DocuLegalApi.createDocument(this.props.template._id,this.props.document).then((res) =>{
            this.props.onPreviewCreated(res);
        }).catch((err) =>{
            this.props.onSavedFailed(err);
        })
    }
    handleCreateAccountPopup(showCreateAccountPopup){
        this.setState({
            showCreateAccountPopup: showCreateAccountPopup
        })
    }
    confirmBtn(){
        DocuLegalApi.getMe().then((u) =>{
            if(u.success){
                this.creditsPopup.open();
            }else{
                this.handleCreateAccountPopup(true);
            }
        }).catch((err) =>{
            this.handleCreateAccountPopup(true);
        })
    }
    goToLogin(){
        window.location.href = "/login";
    }
    goToSignIn(){
        window.location.href = "/sign-in";
    }
    render(){
        let body = 'Una vez generado el documento no podrá ser editado o modificado.';
        return (
            <div className={'ml-auto'}>
                <DLCreateAccountComponent
                    onLogin={() => this.goToLogin()}
                    onSignup={() => this.goToSignIn()}
                    onHide={() => this.handleCreateAccountPopup(false)}
                    show={this.state.showCreateAccountPopup}
                ></DLCreateAccountComponent>
                <button className={'btn btn-success'} onClick={() => this.confirmBtn()}>Generar documento
                    <CreditsPopup
                        body={body}
                        title={'Preparar documento para firma'}
                        ref={(ref) => this.creditsPopup = ref}
                        showCredits={false}
                        onConfirm={() => this.savePreview()}
                        confirmButtonText={'Firmar'}
                        cancelButtonText={'Seguir Editando'}
                        ></CreditsPopup>
                </button>
            </div>

        )
    }
}
DocumentUploader.propTypes = {
    /** Document to be render. */
    template: PropTypes.object,
    document: PropTypes.object,
    onSavedFailed: PropTypes.func,
};

DocumentUploader.defaultProps = {
    template: {},
    document: {},
    onSavedFailed: () => {}
};

export default DocumentUploader;
