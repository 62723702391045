/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by America Mendoza <amendoza@nodeport.co> October 2020
*/

// Regular imports
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import styling
import '../style/bootstrap-reset.css'
import '../style/style.css'
import '../style/style-responsive.css'

// Import components
import DLHeader from "../components/DLHeader";
import CFDocumentHistory from "../components/CFDocumentHistory";
import CFPaymentHistory from "../components/CFPaymentHistory";
import CFProfile from "../components/CFProfile";
import DLUpgradePlan from "../components/DLUpgradePlan";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import DLIf from "../components/render/DLIf";


// Import API
const DocuLegalApi = require('../api/DocuLegalApi');

class ProfileView extends React.Component {
    constructor(params) {
        super(params);

        const section = this.props.location.state ? this.props.location.state.section : 'profile';
        this.state = {
            renderSection: section,
            authorized:false
        }
    }

    componentDidMount() {
        this.getMe();
    }

    getMe(){
        DocuLegalApi.getMe().then((u) =>{
            if(u.success){
                this.setState({
                    authorized:true,
                });
            }else{
                this.setState({
                    authorized:false,
                });
            }
        }).catch((err) =>{
            this.setState({
                authorized:false,
            });
        })
    }

    renderSection(){
        const afterSuccessfulPaymentUrl = this.props.location.state ? this.props.location.state.afterSuccessfulPaymentUrl : null;
        switch(this.state.renderSection){
            case('profile'):
                return <CFProfile />
                break;
            case('documents'):
                return <CFDocumentHistory />
                break;
            case('payments'):
                return <CFPaymentHistory />
                break;
            case('password'):
                return <CFPaymentHistory />
                break;
            case('shop'):
                return <DLUpgradePlan history={this.props.history} afterSuccessfulPaymentUrl={afterSuccessfulPaymentUrl}/>
                break;
            default: return null
        }
    }

    render() {
        const content = this.state.renderSection;
        if(this.state.authorized){
            return (
                <section id="container" className='light-sidebar-nav'>
                    {/*header start*/}
                    <DLHeader />
                    {/*header end*/}
                    {/*sidebar start*/}
                    <aside>
                        <div id="sidebar"  className="nav-collapse ">
                            {/* sidebar menu start*/}
                            <ul className="sidebar-menu" id="nav-accordion">
                                <OverlayTrigger trigger={'hover'} key={'tooltip-profile'} placement={'right'} overlay={
                                    <Tooltip id={'tooltip-profile'} className={'tooltip-h'}>
                                        Mi cuenta
                                    </Tooltip>
                                }>
                                    <li className={'cursor-pointer'}>
                                        <a className={content === 'profile' ? 'active' : ''}
                                           onClick={() => this.setState({renderSection: 'profile'})}>
                                            <FontAwesomeIcon  icon='user' size={'lg'}/>
                                            <span style={{marginLeft:'6px'}}>Mi cuenta</span>
                                        </a>
                                    </li>
                                </OverlayTrigger>

                                <OverlayTrigger trigger={'hover'} key={'tooltip-docs'} placement={'right'} overlay={
                                    <Tooltip id={'tooltip-docs'} className={'tooltip-h'}>
                                        Mis documentos
                                    </Tooltip>
                                }>
                                    <li className={'cursor-pointer'}>
                                        <a className={content === 'documents' ? 'active' : ''}
                                           onClick={() => this.setState({renderSection: 'documents'})}>
                                            <FontAwesomeIcon  icon='file-alt' size={'lg'}/>
                                            <span style={{marginLeft:'6px'}}>Mis documentos</span>
                                        </a>
                                    </li>
                                </OverlayTrigger>

                                <OverlayTrigger trigger={'hover'} key={'tooltip-payments'} placement={'right'} overlay={
                                    <Tooltip id={'tooltip-payments'} className={'tooltip-h'}>
                                        Mis pagos
                                    </Tooltip>
                                }>
                                    <li className={'cursor-pointer'}>
                                        <a className={content === 'payments' ? 'active' : ''}
                                           onClick={() => this.setState({renderSection: 'payments'})}>
                                            <FontAwesomeIcon  icon='receipt' size={'lg'}/>
                                            <span style={{marginLeft:'6px'}}>Mis pagos</span>
                                        </a>
                                    </li>
                                </OverlayTrigger>

                                <OverlayTrigger trigger={'hover'} key={'tooltip-shop'} placement={'right'} overlay={
                                    <Tooltip id={'tooltip-shop'} className={'tooltip-h'}>
                                        Comprar
                                    </Tooltip>
                                }>
                                    <li className={'cursor-pointer'}>
                                        <a className={content === 'shop' ? 'active' : ''}
                                           onClick={() => this.setState({renderSection: 'shop'})}>
                                            <FontAwesomeIcon  icon='shopping-basket' size={'lg'}/>
                                            <span style={{marginLeft:'6px'}}>Comprar</span>
                                        </a>
                                    </li>
                                </OverlayTrigger>
                            </ul>
                            {/* sidebar menu end*/}
                        </div>
                    </aside>
                    {/*sidebar end*/}
                    {/*main content start*/}
                    <section id="main-content-profile" >
                        <section className="wrapper" style={{maxWidth: '1200px'}}>
                            {this.renderSection()}
                        </section>
                    </section>
                    {/*main content end*/}
                </section>
            )
        }else{
            return null
        }
    }
}
export default ProfileView;
