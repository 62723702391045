/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Darien Miranda <dmiranda@nodeport.co> July 2020 &
* America Mendoza <amendoza@nodeport.co> August 2020
*/
import React from 'react';
import PropTypes from 'prop-types';

//import components
import DLErrorDisplay from "../DLErrorDisplay";

class DLPassword extends React.Component{
    constructor(params) {
      super(params);
      this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event){
      const fieldName = this.props.field.fieldName;
      const value     = event.target.value;
      this.props.onChange({[fieldName]:{
              field:this.props.field,
              value:value
          }})

      this.setState({
          [fieldName]:value
      });
    }

    render(){
      const name = this.props.field.fieldName;
      const field = this.props.field;
      const errors = this.props.errors;
      return(
        <div className="form-group form-validation clearfix">
          <label className="col-lg-12 control-label " htmlFor={name}>{field.description} </label>
          <div className="col-lg-12">
            <input className={errors.length > 0 ? "form-control error" : "form-control"}
                   id={name}
                   name={name}
                   type="password"
                   onChange={(e) => this.handleChange(e)}
            />
            <DLErrorDisplay errors={errors}></DLErrorDisplay>
          </div>
        </div>
      )
    }
}
export default DLPassword;
DLPassword.propTypes = {
    field    : PropTypes.object,
    onChange : PropTypes.func,
    errors   : PropTypes.array
};


DLPassword.defaultProps = {
    field: {
      handleName:'',
      type:'text',
      validator:'',
      description:'',
      fieldName:'',
      defaultValue:''
    },
    onChange : (change) => {},
    errors   : []
};
