const DocumentUtils = {
    getAllFields: (template) =>{
        let allfields = template.doc.forms.map(f => f.fields);
        allfields = [].concat.apply([],allfields);
        return allfields;
    },
    getPlaceholders: (template) =>{
        const allFields = DocumentUtils.getAllFields(template);
        let fields = allFields.map(f => {
            return{
                [`${f.handleName}_PLACEHOLDER`] : f.placeholder
            }
        });
        let placeholders = {};
        fields.forEach((f) => {
            const key = Object.keys(f);
            placeholders[key] = f[key];
        });
        return placeholders;
    }
}

export default DocumentUtils;