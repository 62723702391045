/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by America Mendoza <amendoza@nodeport.co> October 2020
*/

// Regular imports
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import styling
import '../style/jquery.steps.css'
import '../style/bootstrap-reset.css'
import '../style/style.css'
import '../style/style-responsive.css'

// Import components
import DocuLegalApi from "../api/DocuLegalApi";
import BootstrapTable from 'react-bootstrap-table-next';
import moment from "moment";
import {Button,Dropdown,DropdownButton, ButtonGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import Swal from "sweetalert2";
import DLIf from "../components/render/DLIf";

import DLElse from "./render/DLElse";
import CreditsPopup from "./store/CreditsPopup";


class CFDocumentHistory extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            documents:[],
        }
        this.resendSignatureRequest = this.resendSignatureRequest.bind(this);
        this.optionsFormatter = this.optionsFormatter.bind(this);
        this.reload = this.reload.bind(this);
    }
    componentDidMount() {
        DocuLegalApi.getMyDocuments().then((res) =>{
            this.setState({
                documents:res.documents
            });
        })
    }
    reload() {
        DocuLegalApi.getMyDocuments().then((res) =>{
            this.setState({
                documents:res.documents
            });
        })
    }
    dateFormatter(date){
        return moment(date).locale('es-mx').format('LL');
    }
    statusFormatter(isDraft){
        return isDraft ? 'Sin firmar' : 'Firmado';
    }
    signatureTypeFormatter(signatories){
        const methods = signatories.map((s) => s.signingMethod);
        if(methods.length === 0){
            return 'Ninguno';
        }
        switch (methods[0]){
            case 'digital':
                return 'Digital'
            case 'fiel':
                return 'Fiel'
            case 'autograph':
                return 'Firma Autógrafa'
            default:
                return ''
        };
    }
    documentTypeFormatter(template){
        let badgeType = '';
        switch (template.category){
            case 'pyme':
                badgeType = 'badge badge-primary';
                break;
            case 'personal':
                badgeType = 'badge badge-success';
                break;
            default:
                badgeType = 'badge badge-warning';
        }
        return (
            <span className={badgeType}>{template.name}</span>
        );
    }
    signaturesFormatter(_id,row){
        return (
            <span className={"table-signatories-list"}>
                <DLIf if={row.signatories.length === 0}>
                    <a href={`/documents/${row.template.url}/${_id}/preview`}>Solicitar firmas</a>
                    <DLElse>
                        {
                            row.signatories.map((s) =>
                                <div>
                                    <DLIf if={s.isSigned}>
                                        <OverlayTrigger key={'tooltip-signed'} placement={'top'} overlay={
                                            <Tooltip id={'tooltip-signed'}>
                                                Firmado
                                            </Tooltip>
                                        }>
                                            <span className={'badge badge-success mr-1'}><FontAwesomeIcon icon={'check'}></FontAwesomeIcon></span>
                                        </OverlayTrigger>
                                        <DLElse>
                                            <DLIf if={s.signingMethod === 'autograph'}>
                                                <OverlayTrigger key={'tooltip-not-signed'} placement={'top'} overlay={
                                                    <Tooltip id={'tooltip-not-signed'}>
                                                        No aplica
                                                    </Tooltip>
                                                }>
                                                    <span className={'badge badge-warning mr-1'}><FontAwesomeIcon icon={'minus'}></FontAwesomeIcon></span>
                                                </OverlayTrigger>
                                                <DLElse>
                                                    <OverlayTrigger key={'tooltip-not-signed'} placement={'top'} overlay={
                                                        <Tooltip id={'tooltip-not-signed'}>
                                                            Sin firmar
                                                        </Tooltip>
                                                    }>
                                                        <span className={'badge badge-danger mr-1'}><FontAwesomeIcon icon={'times'}></FontAwesomeIcon></span>
                                                    </OverlayTrigger>
                                                </DLElse>
                                            </DLIf>
                                        </DLElse>
                                    </DLIf>
                                    {s.name} { s.email ? `(${s.email})` : ``}
                                </div>
                            )
                        }
                    </DLElse>
                </DLIf>
            </span>
        )
    }
    cancelSignatures(data){
        DocuLegalApi.cancelSignatures(data.id).then((r) =>{
            this.reload();
        }).catch((ex) =>{
            Swal.fire(
                "Error al editar las firmas",
                "No pudimos editar las firmas",
                "error"
            );
        })
    }
    resendSignatureRequest(documentId){
        DocuLegalApi.resendSignatureRequest(documentId).then((r) =>{
            Swal.fire(
                "Correos reenviados",
                "Se reenviaron las solicitudes de firma",
                "success"
            );
        }).catch((ex) =>{
            Swal.fire(
                "Error al reenviar los correos",
                "No se pudieron reenviar los correos",
                "error"
            );
        })
    }
    downloadPDF(documentId,row){
        const isSignedArray = row.signatories ? row.signatories.map(item => item.isSigned) : [];
        const isSignedByAll = isSignedArray.every((s) => s === true);
        if(row.signatories.length === 0){
            const url = `${process.env.REACT_APP_API_URL}/documents/${documentId}/preview`
            window.open(url,'_blank');
        }
        else if(row.signatories[0].signingMethod === 'fiel' && isSignedByAll){
            const url = `${process.env.REACT_APP_API_URL}/documents/${documentId}/downloadMIFIELDoc`
            window.open(url,'_blank');
        }else{
            const url = `${process.env.REACT_APP_API_URL}/documents/${documentId}/preview`
            window.open(url,'_blank');
        }

    }
    downloadMIFIELPDF(documentId,row){
        const isSignedArray = row.signatories ? row.signatories.map(item => item.isSigned) : [];
        const isSignedByAll = isSignedArray.every((s) => s === true);
        if(row.signatories.length > 0){
            if(row.signatories[0].signingMethod === 'fiel' && isSignedByAll){
                const url = `${process.env.REACT_APP_API_URL}/documents/${documentId}/downloadMIFIELDoc`
                window.open(url,'_blank');
            }
        }

    }
    downloadMIFIELXML(documentId,row){
        const isSignedArray = row.signatories ? row.signatories.map(item => item.isSigned) : [];
        const isSignedByAll = isSignedArray.every((s) => s === true);
        if(row.signatories.length > 0){
            if(row.signatories[0].signingMethod === 'fiel' && isSignedByAll){
                const url = `${process.env.REACT_APP_API_URL}/documents/${documentId}/downloadMIFIELDoc?extension=xml`
                window.open(url,'_blank');
            }
        }
    }
    emptyValue(){
        return (<p align={"center"}>Aún no has creado tu primer documento</p>)
    }
    optionsFormatter(_id,row){
        const isSignedArray = row.signatories ? row.signatories.map(item => item.isSigned) : [];
        const isSignedByAll = isSignedArray.every((s) => s === true);
        let signingMethod = row.signatories.map((s) => s.signingMethod);
        if(signingMethod.length > 0){
            signingMethod = signingMethod[0];
        }else{
            signingMethod = 'none'
        }
        let resendTooltip = '';
        if(!isSignedByAll) {
            resendTooltip = 'Reenviar correos';
        }else if(isSignedArray.length === 0){
            resendTooltip = 'Este documento no tiene firmantes';
        }else{
            resendTooltip = 'Los participantes ya han firmado';
        }
        if(signingMethod === 'autograph'){
            resendTooltip = 'No se pueden reenviar los correos';
        }


        let resendVariant = "success";
        let cancelToolTip = isSignedByAll ? 'No se pueden editar las firmas cuando ya todos firmaron' : 'Editar firmas';
        if(isSignedArray.length === 0){
            cancelToolTip = 'No se pueden editar las firmas cuando no hay firmantes';
        }

        let cancelVariant = isSignedByAll ? 'default' : 'warning';
        let isFIELDownloadAvailable = signingMethod === 'fiel' && isSignedByAll;
        return(
            <div className={'d-flex justify-content-center'}>
                <OverlayTrigger key={'tooltip-downloads'} placement={'top'} overlay={
                                    <Tooltip id={'tooltip-downloads'}>
                                        Descargar
                                    </Tooltip>
                                }>
                    <div  className={'mr-2'}>

                        <Dropdown as={ButtonGroup} id="bg-nested-dropdown" disabled={true}>
                            <Button variant={"info"} onClick={() => this.downloadPDF(_id,row)}  >
                                <FontAwesomeIcon icon={['fas','download']}>
                                </FontAwesomeIcon>
                            </Button>
                            <Dropdown.Toggle split className='btn btn-info btn-aux' id="dropdown-split-basic" />
                            <Dropdown.Menu >
                                <DLIf if={signingMethod === 'fiel'}>
                                    <OverlayTrigger key={'tooltip-downloads-pdf'} placement={'top'} overlay={
                                        <Tooltip id={'tooltip-downloads-pdf'}>
                                            {isFIELDownloadAvailable ? 'PDF firmado con FIEL' : 'Disponible hasta que todos hayan firmado'}
                                        </Tooltip>
                                    }>
                                        <Dropdown.Item
                                            className={isFIELDownloadAvailable ? '' : 'disabled-dropdown-option'}
                                            onClick={() => this.downloadMIFIELPDF(_id,row)}
                                            eventKey="1">
                                            PDF
                                        </Dropdown.Item>
                                    </OverlayTrigger>
                                    <OverlayTrigger key={'tooltip-downloads-xml'} placement={'top'} overlay={
                                        <Tooltip id={'tooltip-downloads-xml'}>
                                            {isFIELDownloadAvailable ? 'PDF firmado con FIEL' : 'Disponible hasta que todos hayan firmado'}
                                        </Tooltip>
                                    }>
                                        <Dropdown.Item
                                            className={isFIELDownloadAvailable ? '' : 'disabled-dropdown-option'}
                                            onClick={() => this.downloadMIFIELXML(_id,row)}
                                            eventKey="2">
                                            XML
                                        </Dropdown.Item>
                                    </OverlayTrigger>
                                    <DLElse>
                                        <Dropdown.Item eventKey="3" onClick={() => this.downloadPDF(_id,row)}>PDF</Dropdown.Item>
                                    </DLElse>
                                </DLIf>
                            </Dropdown.Menu>

                        </Dropdown>
                    </div>

                </OverlayTrigger>
                <OverlayTrigger key={'tooltip-resend'} placement={'top'} overlay={
                    <Tooltip id={'tooltip-resend'}>
                        {resendTooltip}
                    </Tooltip>
                }>
                    <Button className={'mr-2'} variant={resendVariant}
                            onClick={() => this.resendSignatureRequest(_id)}
                            disabled={(isSignedByAll || signingMethod === 'autograph')}>
                        <FontAwesomeIcon icon={['fas','paper-plane']}>
                        </FontAwesomeIcon>
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger key={'tooltip-cancel'} placement={'top'} overlay={
                    <Tooltip id={'tooltip-cancel'}>
                        {cancelToolTip}
                    </Tooltip>
                }>
                    <Button variant={cancelVariant}
                            onClick={() => this.cancelPopup.open({id:_id})}
                            disabled={isSignedByAll || isSignedArray.length === 0 }>
                        <FontAwesomeIcon icon={['fas','file-signature']}>
                        </FontAwesomeIcon>
                    </Button>
                </OverlayTrigger>
            </div>
        )
    }
    cancelFormatter(_id,row) {
        return(<div>X</div>);
    }
    render() {
        const columns = [
            {
                dataField: 'template',
                text: 'Documento',
                formatter: this.documentTypeFormatter,
            },
            {
                dataField: 'created_at',
                formatter: this.dateFormatter,
                text: 'Fecha de creación',
            },
            {
                dataField: '_id',
                formatter: this.signaturesFormatter,
                text: 'Firmas'
            },
            {
                dataField: 'signatories',
                formatter: this.signatureTypeFormatter,
                text: 'Tipo de firmado'
            },
            {
                dataField: '_id',
                formatter: this.optionsFormatter,
                text:'',
                headerStyle: (column, colIndex) => {
                    return { width: '200px'};
                }
            }
        ];
        return (
            <div className="row">
                <CreditsPopup
                    body={'Si elegiste firmar con FIEL se te devolverá 1 crédito.'}
                    title={'¿Cancelar solicitud de firmado?'}
                    confirmButtonText={'Si'}
                    cancelButtonText={'No'}
                    ref={(ref) => this.cancelPopup = ref}
                    onConfirm={(data) => this.cancelSignatures(data)}></CreditsPopup>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            Historial de documentos
                        </div>
                        <div className="card-body" style={{overflowX:'auto'}}>
                            <BootstrapTable
                                striped={true}
                                keyField='id'
                                data={ this.state.documents }
                                columns={ columns }
                                noDataIndication={this.emptyValue()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CFDocumentHistory;
