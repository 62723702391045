/* Copyright (C) StartupLab MX - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Israel Arellano <Isra_darrell@startuplab.mx> September 2020
*/

import React from 'react';
import PropTypes from 'prop-types';
import DLElse from './DLElse'

class DLIf extends React.Component {
    constructor(props) {
        super(props);
        this.getDLElse = this.getDLElse.bind(this);
        this.getChilds = this.getChilds.bind(this);
    }

  getDLElse() {
    let dlElse = Array.isArray(this.props.children) &&
        this.props.children.filter((el) => {
            return el.type === DLElse;
        })[0] || null;

    return dlElse;

  }

  getChilds() {
    let content = this.props.children.filter((el) => {
        return el.type !== DLElse;
    });
    if (content.length === 1) {
        return content[0];
    } else if (content.length > 1){
        return <div>{content}</div>;
    }

    return content;

  }

  render() {
    if (this.props.if === true) {
        if (Array.isArray(this.props.children)) {
            return this.getChilds();
        }
        return this.props.children;
    }
    if (!this.props.if) {
        return this.getDLElse();
    }

    return null;
    
  }

};

DLIf.propTypes = {
    /** Conditional */
    if: PropTypes.bool,
}
DLIf.defaultProps = {
    if: false
};

export default DLIf;