/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Oscar Pena <opena@nodeport.co> October 2020
*/

// Regular imports
import React from "react";

// Import styling
import '../../../style/bootstrap-reset.css'
import '../../../style/style.css'
import '../../../style/style-responsive.css'

// Import components
import DLHeader from "../../../components/DLHeader";
import BootstrapTable from 'react-bootstrap-table-next';
import DocuLegalApi from "../../../api/DocuLegalApi";
import DLIf from "../../../components/render/DLIf";
import Swal from "sweetalert2";
import AdminSidebar from "../../../components/AdminSidebar";

class AdminUsersView extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            users: [

            ],
            renderContent: '',
            user: '',
            selected: [],
            blockedUsers: [],
            authorized:false
        };

    }
    componentDidMount() {
        DocuLegalApi.getMe().then((res) => {
            if(res.success){
                const roles = res.user.roles ? res.user.roles : [];
                if(roles.includes('admin')){
                    this.setState({
                        authorized:true
                    })
                }
            }
        });
        this.getUsers()

    }
    getUsers(){

        DocuLegalApi.getUsers().then((res) =>{
            let position  = 1;
            let usersf    = [];
            let userblock = [];
            if(res.users){
                res.users.map( (user) => {
                    user.position = position;
                    if(user.is_disabled){
                        userblock.push(position)
                    }
                    usersf.push(user);
                    position = position + 1;
                })
            }
            this.setState({
                users:usersf,
                blockedUsers:userblock
            });
        })
    }
    emptyValue(){
        return (<p align={"center"}>Sin usuarios registrados</p>)
    }


    revenueFormatter(revenue){
        return (new Intl.NumberFormat('mx-MX', { style: 'currency', currency: 'MXN' }).format(revenue));
    }
    quotaFormatter(quota){
        if(! quota){
            return '-';
        }
        return quota.quota ? quota.quota : '-';
    }
    nameFormatter(name, row){
        return (
            <a href={`/admin/usuario/${row._id}`}>
                <span>{name} {row.last_name}</span>
            </a>
        );
    }
    is_disabledFormatter(is_disabled){
         return is_disabled ? 'Bloqueado' : '';
    }

    ///Delete user section
    handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            this.setState(() => ({
                selected: [...this.state.selected, row._id],
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter(x => x !== row._id),
            }));
        }
    }
    handleOnSelectAll = (allSelected, rows) => {
        this.setState(() => ({
            selected: allSelected ? rows : []
        }));
    }
    closeModal() {
        this.setState({ open: false });
    }

    confirmDelete(){
        Swal.fire({
            title: '¿Está seguro que desea borrar a los usuarios seleccionados?',
            text: 'Los usuarios borrados no se podrán recuperar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, borrar',
            cancelButtonText: 'No, mantenerlos',
            customClass:{
                confirmButton: 'swal2-danger'
            }
        }).then((result) => {
            if (result.value) {
                this.deleteUsers()
            }
        })
    }
    deleteUsers(){
        this.state.selected.map((user)=>{
            DocuLegalApi.deleteUsers(user).then((res) =>{
                if(!res.success){
                    Swal.fire({
                        title: 'Borrar usuario',
                        text: 'No se pudo borrar un usuario, intentelo nuevamente.',
                        icon: 'error',
                    })
                }else{
                    this.getUsers();
                    Swal.fire({
                        title: 'Usuarios borrados',
                        text: 'Se han borrado con éxito los usuarios',
                        icon: 'success',
                    })

                }
            })
        })

        this.setState(() => ({
            selected: []
        }));
    }
    confirmDisableUser(){
        Swal.fire({
            title: '¿Está seguro que desea bloquear a los usuarios seleccionados?',
            text: 'Los usuarios bloqueados no tendrán acceso al servicio',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, bloquear',
            cancelButtonText: 'No, mantenerlos',
            customClass:{
                confirmButton: 'swal2-danger'
            }
        }).then((result) => {
            if (result.value) {
                this.disableUsers()
            }
        })
    }
    disableUsers(){
        this.state.selected.map((user)=>{
            DocuLegalApi.disableUsers(user).then((res) =>{
                if(!res.success){
                    Swal.fire({
                        title: 'Bloquear usuario',
                        text: 'No se pudo bloquear a un usuario, inténtelo nuevamente.',
                        icon: 'error',
                    })
                }else{
                    this.getUsers();
                    this.setState(() => ({
                        selected: []
                    }));
                    Swal.fire({
                        title: 'Usuarios bloqueados',
                        text: 'Se han bloqueado con éxito los usuarios',
                        icon: 'success',
                    });

                }
            })
        })


    }
    render() {
        const columns = [
            {
                dataField: 'name',
                text: 'Nombre completo',
                formatter: this.nameFormatter,
                headerStyle: (column, colIndex) => {
                    return { width: '200px'};
                }

            },
            {
                dataField: 'email',
                text: 'E-mail',
            },
            {
                dataField: 'quota',
                formatter: this.quotaFormatter,
                text: 'Cuota'
            },
            {
                dataField: 'revenue',
                formatter: this.revenueFormatter,
                text: 'Total Gastado'
            },
            {
                dataField: 'is_disabled',
                formatter: this.is_disabledFormatter,
                text: 'Estado'
            }

        ];
        const selectRow = {
            mode: 'checkbox',
            clickToSelect:true,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll,
            nonSelectable: this.state.blockedUsers
        };
        if(this.state.authorized === false){
            return null;
        }
        return (
            <section id="container" className='light-sidebar-nav'>
                {/*header start*/}
                <DLHeader />
                {/*header end*/}
                {/*sidebar start*/}
                <aside>
                    <AdminSidebar activeUsers/>
                </aside>
                {/*sidebar end*/}
                <section id="main-content-profile">
                    <section className="wrapper" style={{maxWidth: '1200px'}}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className={'card-header'}>Usuarios</div>
                                    <div className="card-body" style={{overflowX:'auto'}}>
                                        <BootstrapTable
                                            striped={true}
                                            keyField='position'
                                            data={ this.state.users}
                                            columns={ columns }
                                            noDataIndication={this.emptyValue()}
                                            selectRow={ selectRow }
                                        />
                                    </div>
                                </div>
                            </div>
                            <DLIf if={this.state.selected.length > 0}>
                                <div className="col-lg-12" display={'block'}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className={'float-right'}>
                                                <button  className='btn btn-warning btn-sm' onClick={() => this.confirmDisableUser()}>
                                                    Bloquear Usuarios
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DLIf>
                        </div>
                    </section>
                </section>
            </section>
        )
    }
}
export default AdminUsersView;
