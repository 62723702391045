/* Copyright (C) Nodeport SA de CV. - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written by Darien Miranda <dmiranda@nodeport.co> & America Mendoza <amendoza@nodeport.co> November 2020
*/
import React from "react";
import PropTypes from "prop-types";
import DoculegalAPI from '../../api/DocuLegalApi';
import Swal from "sweetalert2";

class CreditsPopup extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
            quota:''
        }
    }
    open(data){
        DoculegalAPI.getQuota().then((q) =>{
            this.setState({
                quota:q.quota
            })
            const _html = this.props.showCredits ? `${this.props.body}<br></br><b>Créditos restantes: ${q.quota} </b>` : this.props.body;
            Swal.fire({
                icon: this.props.icon,
                title: this.props.title,
                html: _html,
                showCancelButton: true,
                confirmButtonText: this.props.confirmButtonText,
                cancelButtonText: this.props.cancelButtonText,
                customClass: {
                    confirmButton: 'swal2-success',
                    cancelButton: 'swal2-danger'
                }
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.props.onConfirm(data);
                }else{
                    this.props.onCancel(data);
                }
            })
        })

    }
    render(){
        return (
            null
        )
    }
}
CreditsPopup.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    confirmButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    showCredits: PropTypes.bool
};

CreditsPopup.defaultProps = {
    icon: 'warning',
    title: '',
    body: '',
    onConfirm: () => {},
    onCancel: () => {},
    confirmButtonText: `Generar documento`,
    cancelButtonText: `Continuar en el documento`,
    showCredits:true
};

export default CreditsPopup;
