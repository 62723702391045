import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
//import style
import './App.css';
import './style/style.css';
import './style/style-responsive.css'
//import views
import DocumentEditorView from "./views/DocumentEditorView";
import DocumentsView from "./views/DocumentsView";
import SignInView from "./views/auth/SignInView";
import DocumentPreviewView from "./views/DocumentPreviewView";
import DigitalSignRequestView from "./views/DigitalSignRequestView";
import LoginView from './views/auth/LoginView';
import UpgradePlanView from "./views/UpgradePlanView";
import PaymentView from "./views/PaymentView";
import ProfileView from "./views/ProfileView";
import UserView from "./views/admin/users/UserView";
import DisableUserView from "./views/admin/users/DisabledUsersView";
import ResetPasswordView from "./views/ResetPasswordView";
import RequestPasswordResetView from "./views/auth/RequestPasswordResetView"
import UProfile from "./views/admin/users/UserProfile";
import AfterSignatureView from "./views/AfterSignatureView";

//import fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fas, fab, far);

export default function App() {
  return (
    <Router>
        <Switch>
          <Route path="/reestablecer-contrasena/:code"
                 component={ResetPasswordView}>
           </Route>
          <Route path="/recuperar-contrasena"
                 component={RequestPasswordResetView}>
          </Route>
          <Route path="/perfil"
                 component={ProfileView}>
          </Route>
          <Route path="/pagar-plan"
                 component={PaymentView}>
          </Route>
          <Route path="/comprar-paquete"
                 component={UpgradePlanView}>
          </Route>
          <Route path="/sign-in"
                 component={SignInView}>
          </Route>
          <Route path="/login"
                 component={LoginView}>
          </Route>
          {/* Note how these two routes are ordered. The more specific
            path="/documents/:id" comes before path="/documents" so that
            route will render when viewing an individual document
            https://reactrouter.com/web/guides/primary-components */}
          <Route path="/documents/:type/:id/requestDigitalSignature"
                 component={DigitalSignRequestView}>
          </Route>
          <Route path="/documents/:type/:id/preview"
                 component={DocumentPreviewView}>
          </Route>
          <Route path="/documents/:type/:id/despues-firma"
                 component={AfterSignatureView}>
          </Route>
          <Route path="/documents/:type"
                 component={DocumentEditorView}>
          </Route>
          <Route path="/documents"
                 component={DocumentsView}>
          </Route>
          <Route path="/admin/usuarios"
                 component={UserView}>
          </Route>
          <Route path="/admin/usuario/:id"
                 component={UProfile}>
          </Route>
          <Route path="/admin/usuarios-bloqueados"
                 component={DisableUserView}>
          </Route>

          {/* If none of the previous routes render anything,
            this route acts as a fallback.
            Important: A route with path="/" will *always* match
            the URL because all URLs begin with a /. So that's
            why we put this one last of all
            https://reactrouter.com/web/guides/primary-components */}
          <Route path="/"
                 component={DocumentsView}>
          </Route>
        </Switch>
    </Router>
  );
}
